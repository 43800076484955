import { useState } from "react";

const PasswordVisibility = () => {
  const [showpassword, setshowpassword] = useState({
    password1: false,
    password2: false,
  });
  const handleShowPassword = (password) => {
    if (password === 1) {
      setshowpassword({ ...showpassword, password1: !showpassword.password1 });
    } else {
      setshowpassword({ ...showpassword, password2: !showpassword.password2 });
    }
  };
  return {
    showpassword,
    setshowpassword,
    handleShowPassword,
  };
};

export default PasswordVisibility;
