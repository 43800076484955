import React from "react";

import Toast from "react-bootstrap/Toast";
import { ToastContainer } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { removeAlert } from "../../redux/actions/alertActions/alertActions";
import {
  AlertCloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
} from "../../constants/images-constants";

const ToastMessage = () => {
  const dispatch = useDispatch();
  const { message, variant, show } = useSelector((state) => state.alertReducer);
  return (
    <>
      {show && (
        <>
          <div className="toast-message">
            <ToastContainer
              className="p-4 message-container"
              position={"top-end"}
              zIndex={22}
            >
              <Toast
                onClose={() => {
                  dispatch(removeAlert());
                }}
                show={show}
                delay={3000}
                autohide
                bg={variant}
              >
                <Toast.Body className="toast-body">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={
                        variant === "success"
                          ? SuccessIcon
                          : variant === "danger"
                          ? ErrorIcon
                          : InfoIcon
                      }
                      alt="alert"
                      className="mr-12"
                    />
                    <span className="flex-grow-1 ">
                      <span
                        className={`fw-bold ${
                          variant === "success"
                            ? "text-success"
                            : variant === "danger"
                            ? "text-error"
                            : "text-info"
                        } `}
                      >
                        {variant === "success"
                          ? "Success!"
                          : variant === "danger"
                          ? "Error!"
                          : "Information!"}
                      </span>{" "}
                      {typeof message === "object" ? message?.message : message}
                    </span>
                    <img
                    className="pointer"
                      src={AlertCloseIcon}
                      alt="close"
                      onClick={() => {
                        dispatch(removeAlert());
                      }}
                    />
                  </div>
                </Toast.Body>
              </Toast>
            </ToastContainer>
          </div>
        </>
      )}
    </>
  );
};

export default ToastMessage;
