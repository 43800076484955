import {
  getRequest,
  postRequest,
} from "../../helpers/utils/axios/axiosMethods";
import axiosRetry from "axios-retry";
import axiosClient from "./../../helpers/utils/axios/axiosClient";

const getCirclePaymentKey = () => {
  return new Promise((resolve, reject) => {
    getRequest("circle/get-public-key-circle")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const createCircleCard = (data) => {
  return new Promise((resolve, reject) => {
    postRequest("circle/create-card", { ...data, line2: "line 2" })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const createCirclePayment = (data) => {
  return new Promise((resolve, reject) => {
    postRequest("circle/create-payment", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getUserCircleCardDetail = (data) => {
  return new Promise((resolve, reject) => {
    getRequest(`circle/get-cards?userId=${data}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const createWalletAddress = (chain, userid) => {
  const createWalletmodal = {
    chain: chain,
    userId: userid,
  };
  return new Promise((resolve, reject) => {
    postRequest(`circle/create-wallet-address`, createWalletmodal)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getUsdcPaymentConfirmation = (walletaddress) => {
  const data = {
    WalletAddress: walletaddress,
  };
  axiosRetry(axiosClient, {
    retries: 12,
    retryDelay: () => 10000,
    retryCondition: () => true,
  });
  return new Promise((resolve, reject) => {
    postRequest(`circle/check-payment-status`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log("!");
        reject(error);
      });
  });
};

const CirclePaymentService = {
  getCirclePaymentKey,
  createCircleCard,
  createCirclePayment,
  getUserCircleCardDetail,
  createWalletAddress,
  getUsdcPaymentConfirmation,
};
export default CirclePaymentService;
