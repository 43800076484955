import React from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { benefitSection } from "../../constants/home-page-constants";

const BenefitSection = () => {
  return (
    <>
      <section className="benefit-section">
        <Container>
          <div className="p-90 d-flex justify-content-center ">
            <h5 className="w-810 fw-bold text-center ">
              {benefitSection.PARAGRAPG_SECTION_PART_ONE}
              <br />
              <br />
              {benefitSection.PARAGRAPH_SECTION_PART_TWO}
            </h5>
          </div>
          <div className="whitelist">
            <h3 className="whitelist-heading">
              {benefitSection.UNLOCK__WORLD_OF_BENEFITS}
            </h3>
            <Row>
              <Col md={7} className="d-flex justify-content-center pt-40">
                <p className="fs-20 lh-36 w-520 ">
                  {benefitSection.BONUS_FOR_JOINING_TEXT}
                  <Link to={"/"} className="navigation-link">
                    {benefitSection.BOUN_FOR_JOINING_LINK}
                  </Link>
                  <br />
                  <br />
                  {benefitSection.TRUST_US_TEXT}
                </p>
              </Col>
              <Col md={5} className="d-flex justify-content-center">
                <div className="w-390">
                  <Form className="formstyle">
                    <Form.Control
                      type="text"
                      id="email"
                      name="email"
                      className=" input mb-40"
                      placeholder="Enter Email"
                    />
                    <Button className="btn-hero w-100 bg-red mb-40">
                      {benefitSection.JOIN_THE_WAITLIST}
                    </Button>
                  </Form>
                  <p className="fs-12">{benefitSection.FOOT_NOTE_TEXT}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BenefitSection;
