import { createMessage, encrypt, readKey } from "openpgp";



export const encryptCardData = async (key, cardDetails) => {
  let keyId;
  const decodedPublicKey = await readKey({ armoredKey: atob(key) });
  const message = await createMessage({ text: JSON.stringify(cardDetails) });
  return encrypt({
    message,
    encryptionKeys: decodedPublicKey,
  }).then((ciphertext) => {
    return {
      encryptedMessage: btoa(ciphertext),
      keyId,
    };
  });
};