import React from "react";
import { Button } from "react-bootstrap";
import { RevealUserNftIcon } from "../../constants/images-constants";
import { mintingText } from "../../constants/messages-constants";
import { useNavigate } from "react-router-dom";

const RevealView = () => {
  const navigate = useNavigate();
  const handleCollection = () => {
    navigate("/profile");
  };
  return (
    <>
      <div className="minting-section">
        <div className="reveal-view-icon">
          <img src={RevealUserNftIcon} alt="icon" />
        </div>
        <Button
          className="btn-primary text-center maxw-232 bottom-content"
          onClick={handleCollection}
        >
          {mintingText.viewCollecton}
        </Button>
      </div>
    </>
  );
};

export default RevealView;
