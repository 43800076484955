import EmailValidationForm from "../../components/auth-pages/email-validation-page/EmailValidationForm";

const Emailvalidation = () => {
  return (
    <>
      <EmailValidationForm />
    </>
  );
};

export default Emailvalidation;
