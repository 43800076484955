import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { RevealNftIcon } from "../../constants/images-constants";
import { mintingText } from "../../constants/messages-constants";
import RevealView from "./RevealView";

const RevealNft = () => {
  const [revealUserNft, setrevealUserNft] = useState(false);
  const handleReveal = () => {
    setrevealUserNft(true);
  };
  return (
    <>
      {revealUserNft ? (
        <RevealView />
      ) : (
        <div className="minting-section">
          <img src={RevealNftIcon} alt="reveal-icon" className="mb-40" />
          <Button
            className="btn-primary text-center maxw-232 bottom-content"
            onClick={handleReveal}
          >
            {mintingText.revealNft}
          </Button>
        </div>
      )}
    </>
  );
};

export default RevealNft;
