const HelloWorldTransaction = () => `\
  import ${process.env.REACT_APP_IMSA_CONTRACT_NAME} from ${process.env.REACT_APP_IMSA_CONTRACT_ADDRESS}

 transaction {

  prepare(acct: AuthAccount) {}

  execute {
    log(HelloWorld.hello())
     log("Hello Again")
  }
 }`;

const script = {
  HelloWorldTransaction,
};

export default script;
