import React from 'react'
import { Link } from 'react-router-dom'

const AuthFooterContent = () => {
  return (
    <>
     <div className="auth-footer-content mt-36">
          <p className="fs-12 text-grey">
            By selecting Sign up with Google or Sign up above, I acknowledge
            that I have read and agree to IMSA Fastlane’s <Link to="/goole"> Terms of Service</Link> and
            <Link to="/goole"> Privacy Policy </Link>in their entirety.
            <br /> <br /> Having trouble creating account? Check out our <Link to="/goole"> help
            center. </Link>
          </p>
        </div>
    </>
  )
}

export default AuthFooterContent