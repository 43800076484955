import React from "react";
import FaqsContentConstants from "./../../constants/faqs-constants/index";

const FaqsTable = () => {
  return (
    <>
      <table className="table ">
        <tr className="p-8">
          <th className="p-8">
            {FaqsContentConstants.TABLE_HEADING_TITLE_ONE}
          </th>
          <th className="p-8">
            {FaqsContentConstants.TABLE_HEADING_TITLE_TWo}
          </th>
          <th className="p-8">
            {FaqsContentConstants.TABLE_HEADING_TITLE_THREE}
          </th>
          <th className="p-8">
            {FaqsContentConstants.TABLE_HEADING_TITLE_FOUR}
          </th>
        </tr>
        <tbody>
          <tr>
            <td>
              <b>{FaqsContentConstants.TABLE_COTACT_HEADING}</b>
              {FaqsContentConstants.TABLE_CONTACT}
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_FROM_USE}</b>
                  {FaqsContentConstants.TABLE_FROM_USE_CONTENT}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>To {FaqsContentConstants.TABLE_TO_CONTENT} </b>
                </li>
                <li>
                  To <b>{FaqsContentConstants.TABLE_TO_PROVIDE}</b>
                  {FaqsContentConstants.TABLE_TO_PROVIDE_DESC}
                </li>
                <li>
                  To <b> {FaqsContentConstants.TABLE_SERVICE}</b>
                  {FaqsContentConstants.TABLE__SERVICE_DESC}
                </li>
                <li>
                  To <b>{FaqsContentConstants.TABLE_ACCOUNT}</b>
                  {FaqsContentConstants.TABLE_ACCOUNT_DESC}
                </li>
                <li>
                  To <b>{FaqsContentConstants.TABLE_PREVENT_DEFAULT}</b>{" "}
                  {FaqsContentConstants.TABLE_PREVENT_DEFAULT_DESC}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_PROVIDER} </b>
                  {FaqsContentConstants.TABLE_PROVIDER_DESC}
                </li>
                <li>
                  {FaqsContentConstants.TABLE_OTHER_COMPANY}{" "}
                  <b>{FaqsContentConstants.TABLE_IMSA_FAMILY}</b>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>{FaqsContentConstants.TABLE_ACCOUNT_REG} </b>
              {FaqsContentConstants.TABLE_REGISTER_INFO}
            </td>
            <td>
              <ul>
                <li>
                  <b>From you</b>
                  {FaqsContentConstants.TABLE_REGISTER_DESC}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_CREATE}</b>{" "}
                  {FaqsContentConstants.TABLE_CREATE_ACCOUNT}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_PROVIDER} </b>
                  {FaqsContentConstants.TABLE_PROVIDER_DESC}
                </li>
                <li>
                  {FaqsContentConstants.TABLE_OTHER_COMPANY}
                  <b>{FaqsContentConstants.TABLE_IMSA_FAMILY}</b>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>{FaqsContentConstants.TABLE_INTEREST}</b>
              {FaqsContentConstants.TABLE_INTEREST_DESC}
            </td>
            <td>
              <ul>
                <li>
                  <b>From </b>
                  {FaqsContentConstants.TABLE_FORM_CONSTANT}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  To <b>{FaqsContentConstants.TABLE_IDENTITY}</b>{" "}
                  {FaqsContentConstants.TABLE_IDENTITY_DESC}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_PROVIDER} </b>
                  {FaqsContentConstants.TABLE_PROVIDER_DESC}
                </li>
                <li>
                  {FaqsContentConstants.TABLE_OTHER_COMPANY}{" "}
                  <b>{FaqsContentConstants.TABLE_IMSA_FAMILY}</b>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>{FaqsContentConstants.TABLE_ONLINE_DEVICE}</b>
              {FaqsContentConstants.TABLE_ONLINE_IDENTIFIER}
            </td>
            <td>
              <ul>
                <li>
                  From
                  <b> {FaqsContentConstants.TABLE_TRACK}</b>
                  {FaqsContentConstants.TABLE_TRACK_DESC}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  To <b>{FaqsContentConstants.TABLE_IMPROVE}</b>
                </li>
                <li>
                  To <b>{FaqsContentConstants.TABLE_EFFECT}</b>
                  {FaqsContentConstants.TABLE_EFFECT_DESC}
                </li>
                <li>
                  To <b>{FaqsContentConstants.TABLE_EXP}</b>
                  {FaqsContentConstants.TABLE_EXP_DESC}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_PROVIDER} </b>
                  {FaqsContentConstants.TABLE_PROVIDER_DESC}
                </li>
                <li>
                  {FaqsContentConstants.TABLE_OTHER_COMPANY}{" "}
                  <b>{FaqsContentConstants.TABLE_IMSA_FAMILY}</b>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>{FaqsContentConstants.TABLE_ACTIVITY}</b>
              {FaqsContentConstants.TABLE_ACTIVITY_DESC}
            </td>
            <td>
              <ul>
                <li>
                  From <b>{FaqsContentConstants.TABLE_TRACKING}</b>
                  {FaqsContentConstants.TABLE_TRACKING_DESC}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  To <b>{FaqsContentConstants.TABLE_IMPROVE}</b>
                </li>
                <li>
                  To <b>{FaqsContentConstants.TABLE_EFFECT}</b>
                  {FaqsContentConstants.TABLE_EFFECT_DESC}
                </li>
                <li>
                  To <b>{FaqsContentConstants.TABLE_EXP}</b>
                  {FaqsContentConstants.TABLE_COSTOMISE}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_PROVIDER} </b>
                  {FaqsContentConstants.TABLE_PROVIDER_DESC}
                </li>
                <li>
                  {FaqsContentConstants.TABLE_OTHER_COMPANY}
                  <b>{FaqsContentConstants.TABLE_IMSA_FAMILY}</b>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>{FaqsContentConstants.TABLE_PAY} </b>
              {FaqsContentConstants.TABLE_PAY_DESC}
            </td>
            <td>{FaqsContentConstants.TABLE_PAY_FOR}</td>
            <td>
              <ul>
                <li>
                  To <b>{FaqsContentConstants.TABLE_PAY_PROCESS}</b>
                  {FaqsContentConstants.TABLE_PAY_REFUND}
                </li>
                <li>
                  <b>{FaqsContentConstants.TABLE_PAY_VERIFY}</b>,
                  {FaqsContentConstants.TABLE_PAY_VERIFY_DESC}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_PROVIDER} </b>
                  {FaqsContentConstants.TABLE_PROVIDER_DESC}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>{FaqsContentConstants.TABLE_LOCATION}</b>
              {FaqsContentConstants.TABLE_LOCATION_DESC}
            </td>
            <td>
              <ul>
                <li>
                  <b>From you </b>
                  {FaqsContentConstants.TABLE_LOCATION_SERVICE}
                </li>
                <li>
                  <b> {FaqsContentConstants.TABLE_PROVIDER} </b>
                  {FaqsContentConstants.TABLE_LOCATION_PROVIDER}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  To <b>{FaqsContentConstants.TABLE_LOCATION_CUSTOM} </b>
                  {FaqsContentConstants.TABLE_LOCATION_CUSTOM_DESC}
                </li>
                <li>
                  To <b>{FaqsContentConstants.TABLE_LOCATION_PROVIDE}</b>
                  {FaqsContentConstants.TABLE_LOCATION_PROVIDE_DESC}
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <b>{FaqsContentConstants.TABLE_PROVIDER} </b>
                  {FaqsContentConstants.TABLE_LOCATION_SERVICE_DESC}
                </li>
                <li>
                  <b>{FaqsContentConstants.TABLE_LOCATION_PARTNER}</b>
                  {FaqsContentConstants.TABLE_LOCATION_PARTER_DESC}
                </li>
                <li>
                  Other <b>{FaqsContentConstants.TABLE_LOCATION_AFFLIATE}</b>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default FaqsTable;
