const getStartedInitialValues = {
    email:""
  }
  let loginInitialValues = {
      email :"",
      password : ""
    }
  
  const signUpInitialValues = {
      firstName: "",
      lastName: "",
      password: "",
    };
  
    const changePasswordInitialValues = {
      password: "",
      confirmpassword: "",
    }; 
    
    const forgotPasswordInitialvalues = {
      email: "",
    };

    const authTextConstant = {
     GET_STARTED_TEXT :" Mi lorem scelerisque accumsan orci bibendum ultrices ultricies suspendisse."
    }
  
    export {
      getStartedInitialValues,
      loginInitialValues,
      signUpInitialValues,
      changePasswordInitialValues,
      forgotPasswordInitialvalues,
      authTextConstant
    }
    