import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoader } from "../../../redux/actions/loaderActions/loadActions";
import authServices from "../../../services/authServices/authService";
import Loader from "../../../helpers/loader/Loader";
import EmailStatus from "../auth-components/EmailStatus";
import { requestErrorMessage } from "../../../constants/messages-constants";


const EmailConfirmForm = () => {
  const [success, setSuccess] = useState(true);
  const [errorMsg, seterrorMsg] = useState("");
  const [alreadyVerified, setalreadyVerified] = useState(false);
  const id = useParams();
  const dispatch = useDispatch();
  const { loadingReducer } = useSelector((state) => state);
  useEffect(() => {
    dispatch(setLoader(true));
    authServices
      .ConfirmEmail(id)
      .then((response) => {
        dispatch(setLoader(false));
        if (response.code === undefined) {
          setSuccess(true);
        } else {
          if (response.response.data.errorCode === 20) {
            setalreadyVerified(true);
            seterrorMsg(response.response.data.message);
          } else {
            seterrorMsg(response.response.data.message);
          }
          setSuccess(false);
          dispatch(setLoader(false));
        }
      })
      .catch((error) => {
        setSuccess(false);
        seterrorMsg(requestErrorMessage.SOMETHING_WENT_WRONG);
        dispatch(setLoader(false));
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="auth-pages">
        <div className="auth-container">
          {loadingReducer.loading ? (
            <>
              <Loader />
              <h4 className="text-uppercase mb-8">
                Account Verified is in progress
              </h4>
              <h4 className="mt-20 text-center">Please wait ...</h4>
            </>
          ) : (
            <>
              {success ? (
                <EmailStatus />
              ) : (
                <>
                  {alreadyVerified ? (
                    <EmailStatus message={errorMsg} />
                  ) : (
                    <>
                      <h4 className="text-uppercase mb-8">
                        Account Verified  progress Fail
                      </h4>
                      <h4>{errorMsg}</h4>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default EmailConfirmForm;
