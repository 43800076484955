import ForgotPasswordForm from "../../components/auth-pages/forgot-password-page/ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <>
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPassword;
