import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ['loadingReducer'] ,
  transforms: [
    encryptTransform({
      secretKey: "haseeb983022",
      onError: function (error) {
        // Handle the error.
      }
    })
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  let store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(thunk),
      typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  let persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
