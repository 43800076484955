import SignUpForm from "../../components/auth-pages/signup-page/SignUpForm";

const Signup = () => {
  return (
    <>
      <SignUpForm />
    </>
  );
};

export default Signup;
