import * as Yup from "yup";
export const CIRCLE_CARD_FORM_VALIDATION = Yup.object().shape({
    cardholdernumber: Yup.string()
      .min(16,"Min  length 16 characters")
      .max(16, "Max  length 16 characters")
      .required("Card number is Required"),

      cvv: Yup.string().matches(/\b\d{3,4}\b/, {message: 'Must be 3 or 4 numbers', excludeEmptyString: true})
      .required("CVV is  Required"),

      cardholdername: Yup.string()
      .min(2, "First name must be minimum 2 characters")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .required("First Name Required")
      .nullable(),

      lastname: Yup.string()
      .min(2, "Last name must be minimum 2 characters")
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .required("Last Name Required")
      .nullable(),

      city:Yup.string()
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .required("City is Required")
      .nullable(),

      country :  Yup.string()
      .matches(/[a-zA-Z]{2,}/g, "Characters must be  two-letter country code")
      .max(2, "Max  length 2 characters")
      .required("Country is Required"),

      district : Yup.string()
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .required("District is  Required"),

      line1 : Yup.string()
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .required("Line 1 is  Required"),

      postalcode: Yup.string()
      .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric")
      .required("Postal code is  Required"),

      expirydate:Yup.string()
      .typeError('Not a valid expiration date. Example: MM/YYYY')
      .max(7, 'Not a valid expiration date. Example: MM/YYYY')
      
      .matches(
        /([0-9]{2})\/([0-9]{4})/,
        'Not a valid expiration date. Example: MM/YYYY'
      ).required('Expiration date is required').test(
        'test-credit-card-expiration-date',
        'Invalid Expiration Date has past',
        expirationDate => {
          if (!expirationDate) {
            return false
          }
          const today = new Date()
          const monthToday = today.getMonth() + 1
          const yearToday = today
            .getFullYear()
            .toString()
          const [expMonth, expYear] = expirationDate.split('/')
          if (Number(expYear) < Number(yearToday)) {
            return false
          } else if (
            Number(expMonth) < monthToday &&
            Number(expYear) <= Number(yearToday)
          ) {
            return false
          }
          return true
        }
      )
      .test(
        'test-credit-card-expiration-date',
        'Invalid Expiration Month',
        expirationDate => {
          if (!expirationDate) {
            return false
          }
            
    
          const [expMonth] = expirationDate.split('/')
    
          if (Number(expMonth) > 12) {
            return false
          }
    
          return true
        }
      )
      ,
  });

