import React from "react";
import { Modal } from "react-bootstrap";

const ModalPopup = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog-centered modal-width "
        contentClassName={`modal-style ${props.centerclassname ? 'd-none' : 'block'}`}
        scrollable={props.scrollable}
      >
        <Modal.Body bsPrefix="modal-body">{props.modelbody}</Modal.Body>
      </Modal>
    </>
  );
};

export default ModalPopup;
