import { type } from "../types";

const initialState = {
  message: "",
  variant: "",
  show:false,
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SHOW_ALERT:
      return {
        ...state,
        message: action.payload.message,
        variant: action.payload.variant,
        show:action.payload.show
      };
    case type.REMOVE_ALERT:
      return {
        ...state,
        message: action.payload.message,
        variant: action.payload.variant,
        show:action.payload.show
      };
    default:
      return state;
  }
};
export default alertReducer;
