import { type } from "../../types";

export const showAlert = (message, variant ,show) => (dispatch) => {
  dispatch({
    type: type.SHOW_ALERT,
    payload: { message: message, variant: variant ,show:show },
  });
};

export const removeAlert = () => (dispatch) => {
  dispatch({
    type: type.REMOVE_ALERT,
    payload: { message: "", variant: "" , show:false },
  });
};
