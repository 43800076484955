import React, { useState } from "react";
import { Formik } from "formik";
import { CIRCLE_CARD_FORM_VALIDATION } from "../../../helpers/validations/circlePaymentValidation/createCardValidation";
import ModalPopup from "../../common/ModalPopup/ModalPopup";
import CirclePaymentService from "../../../services/circlePaymentService/circlePaymentService";
import { encryptCardData } from "../../../services/circlePaymentService/circlePaymentfunction";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../../../redux/actions/alertActions/alertActions";
import CircleCardForm from "./circle-components/CircleCardForm";
import { setLoader } from "./../../../redux/actions/loaderActions/loadActions";
import CirclePaymentOptions from "./CirclePaymentOptions";
import ChooseCircleCard from "./ChooseCircleCard";
import { requestErrorMessage } from "../../../constants/messages-constants";


const CreateCircleCard = ({
  openCircleCard,
  setopenCircleCard,
  setopenPaymentModal,
  circleCardInfo,
  setcircleCardInfo,
  setopenSelectChainModal,
  selectPaymentOption,
  setselectPaymentOption,
  countryCode
}) => {
  const { email, userid } = useSelector((state) => state.loginReducer);
  const [chooseCircleCard, setchooseCircleCard] = useState("");
  const circleCardInitialValues = {
    cardholdernumber: "",
    expirydate: "",
    cvv: "",
    cardholdername: "",
    lastname: "",
    city: "",
    line1: "",
    country: "",
    district: "",
    postalcode: "",
    IsCardSave:false
  };
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    debugger;
    dispatch(setLoader(true));
    CirclePaymentService.getCirclePaymentKey()
      .then(async (resp) => {
        if (resp.data.errorCode === 0) {
          const encryptedData = await encryptCardData(
            resp?.data?.payload?.data?.publicKey,
            {
              number: values.cardholdernumber,
              cvv: values.cvv,
            }
          );
          const [expMonth, expYear] = values.expirydate.split("/");
          const createcardDetails = {
            expYear: parseInt(expYear),
            expMonth: parseInt(expMonth),
            name: values.cardholdername + " " + values.lastname,
            city: values.city,
            line1: values.line1,
            country: values.country,
            district: values.district,
            postalCode: values.postalcode,
            encryptedData: encryptedData.encryptedMessage,
            keyId: resp?.data?.payload?.data?.keyId,
            isCardSave: values.IsCardSave,
            email: email,
            userId: userid ,
          };
         
          CirclePaymentService.createCircleCard(createcardDetails).then(
            (response) => {
              
              dispatch(setLoader(false));
              if (response?.data?.errorCode === 0) {
                setcircleCardInfo({
                  ...circleCardInfo,
                  cardInfo: response.data.payload.data,
                  encryptedData: encryptedData.encryptedMessage,
                  keyId: resp?.data?.payload?.data?.keyId,
                  cardId: response.data.payload.data.id
                });
                setopenCircleCard(false);
                setopenPaymentModal(true);
              } else {
                dispatch(setLoader(false));
                setopenCircleCard(false);
                setselectPaymentOption("pay-with-card")
                dispatch(showAlert(response.data.message, "danger",true));
              }
            }
          ).catch((error) => {
            
            console.log("err",error)
            dispatch(setLoader(false));
                setopenCircleCard(false);
                setselectPaymentOption("pay-with-card")
                dispatch(showAlert(requestErrorMessage.SOMETHING_WENT_WRONG, "danger",true));
          });
        } else {
          dispatch(setLoader(false));
          setselectPaymentOption("pay-with-card")
          dispatch(showAlert(resp.data.message, "danger",true));
          setopenCircleCard(false);
          return;
        }
      })
      .catch((e) => {
        dispatch(setLoader(false));
        setselectPaymentOption("pay-with-card")
        dispatch(showAlert(requestErrorMessage.PAYMENT_FAIL, "danger",true));
        setopenCircleCard(false);
        return;
      });
    //setopenCircleCard(false);
  };
console.log("card exist",circleCardInfo.cardExist)
  return (
    <>
      <ModalPopup
        show={openCircleCard}
        onHide={() => setopenCircleCard(false)}
        modelheading={""}
        showheader={"true"}
        dialogClassName="modal-dialog-centered mwidth-72"
        centerclassname=""
        scrollable={true}
        modelbody={[
          <div className="creditCardFields">
            <div className="mb-40">
              <h4 className="text-uppercase">Get your Platinum access</h4>
              <p className="text-light-grey">
                Amet diam at hendrerit quam nullam iaculis turpis nisl varius.
              </p>
            </div>
            <CirclePaymentOptions
              selectPaymentOption={selectPaymentOption}
              setselectPaymentOption={setselectPaymentOption}
              setopenCircleCard={setopenCircleCard}
              setopenSelectChainModal={setopenSelectChainModal}
            />
            {!circleCardInfo?.cardExist ? (
              <div className="py-4 px-3">
                <Formik
                  validationSchema={CIRCLE_CARD_FORM_VALIDATION}
                  onSubmit={handleSubmit}
                  initialValues={{ ...circleCardInitialValues }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    dirty, 
                    isValid,
                  }) => (
                    <CircleCardForm
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      dirty={dirty}
                      isValid={isValid}
                      setFieldValue={setFieldValue}
                      setopenCircleCard={setopenCircleCard}
                      setopenPaymentModal={setopenPaymentModal}
                      circleCardInfo={circleCardInfo}
                      setcircleCardInfo={setcircleCardInfo}
                      setchooseCircleCard={setchooseCircleCard}
                      countryCode={countryCode}
                    />
                  )}
                </Formik>
              </div>
            ) : (
              <ChooseCircleCard
                chooseCircleCard={chooseCircleCard}
                setchooseCircleCard={setchooseCircleCard}
                setopenCircleCard={setopenCircleCard}
                setopenPaymentModal={setopenPaymentModal}
                circleCardInfo={circleCardInfo}
                setcircleCardInfo={setcircleCardInfo}

              />
            )}
          </div>,
        ]}
      />
    </>
  );
};

export default CreateCircleCard;
