import React from "react";
import ModalPopup from "../../common/ModalPopup/ModalPopup";
import TransactionSuccess from "./circle-components/TransactionSuccess";
import TransactionFailed from "./circle-components/TransactionFailed";

const TransactionStatusModal = (props) => {
  const { openTransactionModal, setopenTransactionModal } = props;
  return (
    <>
      <ModalPopup
        show={openTransactionModal.show}
        onHide={() =>
          setopenTransactionModal({ openTransactionModal, show: false })
        }
        modelheading={""}
        showheader={"true"}
        dialogClassName="modal-dialog-centered mwidth-72"
        centerclassname=""
        modelbody={[
          <>
            {openTransactionModal?.status === "success" ? (
              <TransactionSuccess
                setopenTransactionModal={setopenTransactionModal}
              />
            ) : (
              <TransactionFailed
                setopenTransactionModal={setopenTransactionModal}
              />
            )}
          </>,
        ]}
      />
    </>
  );
};

export default TransactionStatusModal;
