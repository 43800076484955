import React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";

const ClainNftForm = (props) => {
  const { formik, status } = props;
  return (
    <>
      <div className="claim-section">
        <div className="claim-content m-16 ">
          <h4 className="fw-bold fs-28 mb-40">GET STARTED</h4>
          <Form className="formstyle" onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-24">
              <p className="mb-8 fw-semi-bold">First Name</p>

              <Form.Control
                type="text"
                id="firstName"
                name="firstName"
                className="input"
                placeholder="John"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <p className="error">{formik.errors.firstName}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-24">
              <p className="mb-8 fw-semi-bold">Last Name</p>

              <Form.Control
                type="text"
                id="lastName"
                name="lastName"
                className="input"
                placeholder="Doe"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <p className="error">{formik.errors.lastName}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-24">
              <p className="mb-8 fw-semi-bold">Email</p>

              <Form.Control
                type="text"
                id="email"
                name="email"
                className="input"
                placeholder="john@doe.com"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="error">{formik.errors.email}</p>
              ) : null}
            </Form.Group>
            <Form.Group>
              <p className="mb-8 fw-semi-bold">Class</p>

              <Dropdown className="custom-dropdown">
                <Dropdown.Toggle
                  id="dropdown-autoclose-true "
                  className={`${formik.values.class === "" && "text-grey"}`}
                >
                  {formik.values.class === ""
                    ? "Select your class"
                    : formik.values.class}
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom-dropdown-menu">
                  {["DPi", "LMP2", "LMP3", "GTD PRO", "GTD"].map(
                    (type, index) => (
                      <Dropdown.Item
                        href="#"
                        key={index}
                        name={"class"}
                        onClick={() => {
                          formik.setFieldValue("class", type);
                        }}
                      >
                        {type}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {formik.touched.class && formik.errors.class ? (
                <p className="error">{formik.errors.class}</p>
              ) : null}
            </Form.Group>
            <Button
              className="btn-primary w-100 mt-40 mb-16"
              type="submit"
              disabled={status.variant !== "CLAIM"}
            >
              {status.variant}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ClainNftForm;
