const heroSection = {
  LIVE_LIFE_IN_FASTLANE: "Live Life in the Fastlane",
  IMSA_BLOG_URL: "https://imsafastlane.com/news/",
  IMSA_DISCORD_URL: "https://discord.gg/G7MUy6Et"
};
const benefitSection = {
  PARAGRAPG_SECTION_PART_ONE:
    " There are two types of people: the ones who watch the action… and the ones who live for it. IMSA Fastlane puts you on the fast track to own a piece of racing history  and play a part in its future. ",
  PARAGRAPH_SECTION_PART_TWO:
    "With digital collectibles, exclusive access to CONTENTS and unique track-side experiences, you are in the driver’s seat.",
  UNLOCK__WORLD_OF_BENEFITS: "UNLOCK A WORLD OF BENEFITS",
  BONUS_FOR_JOINING_TEXT:
    "As a bonus for joining the waitlist, you’re entered in thedraw for a",
  BOUN_FOR_JOINING_LINK:
    "Fastlane Epic Weekend for four at Daytona International Speedway Event January 2023.",
  TRUST_US_TEXT: "Trust us, you do not want to miss this.",
  JOIN_THE_WAITLIST: "JOIN THE WAITLIST",
  FOOT_NOTE_TEXT:
    " ** Footnote goes in here. Subscribe me up to the IMSA.com newsletter"
};

export { heroSection, benefitSection };
