import React from "react";
import Header from "../../components/common/Header";
import HeroSection from "../../components/home-page/HeroSection";
import BenefitSection from "../../components/home-page/BenefitSection";
import RoadmapSection from "../../components/home-page/RoadmapSection";
import Footer from "../../components/common/Footer";


const Home = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <BenefitSection />
      <RoadmapSection />
      <Footer />
    </>
  );
};

export default Home;
