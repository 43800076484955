import axiosClient from "../../helpers/utils/axios/axiosClient";

const getCountryCode = () => {
  return new Promise((resolve, reject) => {
    axiosClient
      .get(`https://restcountries.com/v2/all?fields=alpha2Code`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const externalService = {
  getCountryCode,
};

export default externalService;
