// File: ./src/config.js

import { config } from "@onflow/fcl";
console.log(process.env.REACT_APP_DAPPER_WALLET_DISCOVERY);
config({
  "discovery.wallet": process.env.REACT_APP_DAPPER_WALLET_DISCOVERY,
  "discovery.wallet.method":
    process.env.REACT_APP_DAPPER_WALLET_DISCOVERY_METHOD,
  "accessNode.api": process.env.REACT_APP_FLOW_ACCESS_NODE,
  "app.detail.title": process.env.REACT_APP_APP_NAME,
  "app.detail.icon": process.env.REACT_APP_APP_ICON
});
