// libraries
import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import alertReducer from './alertReducer';
import loadingReducer from './loadingReducer';


// reducers


export default combineReducers({
    loginReducer,
    alertReducer,
    loadingReducer
    
});