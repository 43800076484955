import React from 'react'
import { useState } from 'react';
import { CloseIcon, ExpandIcon } from '../../../constants/images-constants';


const CircleOrderDetail = () => {
    const [expandOrderDetails, setexpandOrderDetails] = useState(true);
  return (
    <>
    <div className="border-dgrey pt-20 pb-20 px-3  mb-40">
          <div
            className="d-flex justify-content-between"
            onClick={
              expandOrderDetails
                ? () => setexpandOrderDetails(false)
                : () => setexpandOrderDetails(true)
            }
          >
            <p className=" fw-semi-bold text-grey">ORDER SUMMARY</p>
            <img
              className="pointer"
              src={expandOrderDetails ? CloseIcon : ExpandIcon}
              alt="expand-icon"
            />
          </div>
          {expandOrderDetails && (
            <>
              <div className="d-flex justify-content-between mt-8 mb-8">
                <p className="maxw-190">IMSA Fastlane Membership: Platinum X 1</p>
                <p >$200.00</p>
              </div>
              <div className="d-flex justify-content-between mt-8 mb-8">
                <p >Service Fee</p>
                <p >$3.00</p>
              </div>
            </>
          )}
        </div>
        <div className="d-flex justify-content-between p-20 mb-40">
                <p className="text-grey">Total</p>
                <p className="fs-20" >$203.00</p>
              </div>
    </>
  )
}

export default CircleOrderDetail