import { type } from "../types";

const initialState = {
  refreshToken: "",
  accesstoken: "",
  message: "",
  fullName: "",
  walletAddr: "",
  userid: ""
};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOGIN_USER:
      return {
        ...state,
        refreshToken: action?.payload?.refreshToken,
        accesstoken: action?.payload?.accessToken,
        message: "User has been logged in successfully",
        userid: action?.payload?.id,
        walletAddr: action?.payload.userName,
        fullName: action?.payload?.fullName
      };
    case type.LOGIN_FAIL:
      return {
        ...state,
        refreshToken: null,
        accesstoken: null,
        message: action?.payload,
        userid: null,
        walletAddr: null,
        fullName: null
      };
    default:
      return state;
  }
};
export default loginReducer;
