import React from "react";
import CustomCheckbox from "./../../common/CustomCheckbox";

import CircleOrderDetail from "./CircleOrderDetail";
import { Button } from "react-bootstrap";
import { useRef } from "react";
import { CardPayIcon, MasterCardIcon } from "../../../constants/images-constants";

const ChooseCircleCard = (props) => {

  const myRef = useRef(null)
   
  const scrollTo = () => {
    
    window.scrollTo({ behavior: 'smooth', top: myRef.current.scrollIntoView() })
   
  }
  const {
    chooseCircleCard,
    setchooseCircleCard,
    setopenCircleCard,
    setopenPaymentModal,
    setcircleCardInfo,
    circleCardInfo
  } = props;

  const handleChooseCard = () => {
    if (chooseCircleCard === "new-card") {
        setcircleCardInfo({...circleCardInfo, cardExist:false});
    } else {
        setopenCircleCard(false)
      setopenPaymentModal(true);
      
    }
  };

  return (
    <>
      <div className="py-4 px-3">
        <CustomCheckbox
          icon={MasterCardIcon}
          title={`**** ${circleCardInfo?.cardInfo?.last4}`}
          subtitle={`Expiry ${circleCardInfo?.cardInfo?.expMonth}/${circleCardInfo?.cardInfo?.expYear}`}
          selectedChain={chooseCircleCard}
          selected={"previous-card"}
          handleChain={() => {
            scrollTo()
            setchooseCircleCard("previous-card");
          }}
        />
        <CustomCheckbox
          icon={CardPayIcon}
          title={"Add a new Credit/Debit"}
          subtitle={"Powered By Circle"}
          selectedChain={chooseCircleCard}
          selected={"new-card"}
          handleChain={() => {
            scrollTo()
            setchooseCircleCard("new-card");
          }}
        />
      </div>
      <CircleOrderDetail />
      <div className="d-flex " >
        <Button
          type="button"
          className="btn-secondary mr-12 w-50 text-uppercase"
          onClick={() => {
            setopenCircleCard(false);
          }}
        >
          Cancel
        </Button>
        <Button
        ref={myRef}
          type="submit"
          className="btn-primary w-50 text-uppercase"
          onClick={()=> handleChooseCard()}
          disabled={chooseCircleCard===""}
        >
          {chooseCircleCard === "new-card" ? "Add Now" : "Buy Now"}
        </Button>
      </div>
    </>
  );
};

export default ChooseCircleCard;
