import React, { useState } from "react";
import { Button, Form, Dropdown } from "react-bootstrap";
import { CloseIcon, CreditCardIcon, ExpandIcon } from "../../../../constants/images-constants";
import CircleOrderDetail from "../CircleOrderDetail";

const CircleCardForm = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  setFieldValue,
  setopenCircleCard,
  dirty,
  isValid,
  circleCardInfo,
  setcircleCardInfo,
  setchooseCircleCard,
  countryCode,
}) => {
  const [expandDetails, setexpandDetails] = useState(false);
  const handleKeyPress = (e, value) => {
    if (e.which === 8) {
      let val = value;
      if (val.length === 3) {
        val = val.slice(0, val.length - 1);
        setFieldValue("expirydate", val);
      }
    }
  };
  return (
    <>
      <Form className="formstyle" onSubmit={handleSubmit}>
        <Form.Group className="mb-16">
          <p className="mb-8 fw-semi-bold">Card Number</p>
          <div className="showicon">
            <Form.Control
              type="text"
              id="cardholdernumber"
              name="cardholdernumber"
              value={values.cardholdernumber}
              className="input"
              onChange={handleChange}
              maxLength={16}
            />
            <img src={CreditCardIcon} alt="card-icon" />
          </div>
          {errors.cardholdernumber && (
            <p className="error mt-8">{errors.cardholdernumber}</p>
          )}
        </Form.Group>
        <div className="d-flex expiry_cvc mb-24">
          <Form.Group className="w-50 me-3">
            <p className="mb-8 fw-semi-bold">Expiry Date</p>
            <Form.Control
              type="text"
              id="expirydate"
              name="expirydate"
              className="input"
              value={values.expirydate}
              placeholder="MM / YYYY"
              onKeyDown={(e) => handleKeyPress(e, values.expirydate)}
              onChange={(e) => {
                let val = e.target.value;
                if (val.length === 2) {
                  val += "/";
                }
                setFieldValue("expirydate", val);
              }}
            />
            {errors.expirydate && (
              <p className="error mt-8">{errors.expirydate}</p>
            )}
          </Form.Group>
          <Form.Group className="w-50">
            <p className="mb-8 fw-semi-bold">Security Code</p>
            <Form.Control
              type="text"
              id="cvv"
              name="cvv"
              onFocus={() => setexpandDetails(true)}
              className="input"
              onChange={handleChange}
              maxLength={4}
            />
            {errors.cvv && <p className="error">{errors.cvv}</p>}
          </Form.Group>
        </div>
        <div className="grey-border  pt-20 pb-20 px-3 mb-16">
          <div
            className="d-flex justify-content-between  pointer"
            onClick={
              expandDetails
                ? () => setexpandDetails(false)
                : () => setexpandDetails(true)
            }
          >
            <p className=" fw-semi-bold">Billing Details</p>
            <img
              className="pointer"
              src={expandDetails ? CloseIcon : ExpandIcon}
              alt="expand-icon"
            />
          </div>
          {expandDetails && (
            <>
              <Form.Group className="mb-24">
                <p className="mb-8 fw-semi-bold mt-8">First Name</p>
                <Form.Control
                  type="text"
                  id="card-holder-name"
                  name="cardholdername"
                  className="input"
                  onChange={handleChange}
                />
                {errors.cardholdername && (
                  <p className="error mt-8">{errors.cardholdername}</p>
                )}
              </Form.Group>
              <Form.Group className=" mb-24 ">
                <p className="mb-8 fw-semi-bold">Last Name</p>
                <Form.Control
                  type="text"
                  id="lastname"
                  name="lastname"
                  className="input"
                  onChange={handleChange}
                />
                {errors.lastname && (
                  <p className="error mt-8">{errors.lastname}</p>
                )}
              </Form.Group>
              <div className="d-flex expiry_cvc mb-3">
                <Form.Group className=" w-50 me-2">
                  <p className="mb-8 fw-semi-bold">District</p>
                  <Form.Control
                    type="text"
                    id="district"
                    name="district"
                    className="input"
                    onChange={handleChange}
                  />
                  {errors.district && (
                    <p className="error mt-8">{errors.district}</p>
                  )}
                </Form.Group>
                <Form.Group className="w-50 ">
                  <p className="mb-8 fw-semi-bold">City</p>
                  <Form.Control
                    type="text"
                    id="city"
                    name="city"
                    className="input"
                    onChange={handleChange}
                  />
                  {errors.city && <p className="error mt-8">{errors.city}</p>}
                </Form.Group>
              </div>
              <Form.Group className="mb-24">
                <p className="mb-8 fw-semi-bold">Line 1</p>
                <Form.Control
                  type="text"
                  id="line1"
                  name="line1"
                  className="input"
                  onChange={handleChange}
                />
                {errors.line1 && <p className="error mt-8">{errors.line1}</p>}
              </Form.Group>
              <div className="d-flex expiry_cvc mb-24">
                <Form.Group className="w-50  me-2">
                  <p className="mb-8 fw-semi-bold">Country</p>
                  {countryCode.length > 0 ? (
                    <Dropdown className="custom-dropdown">
                      <Dropdown.Toggle id="dropdown-autoclose-true " className={`${values?.country === "" && "text-grey"}`}>
                        {values?.country === "" ? "Select" : values?.country}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="custom-dropdown-menu">
                        {countryCode?.map((code, index) => (
                          <Dropdown.Item
                            href="#"
                            key={index}
                            name={"country"}
                            onClick={() => {
                              setFieldValue("country", code.alpha2Code);
                            }}
                          >
                            {code.alpha2Code}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Form.Control
                      type="text"
                      id="country"
                      name="country"
                      className="input"
                      onChange={handleChange}
                      maxLength={2}
                    />
                  )}

                  {errors.country && (
                    <p className="error mt-8">{errors.country}</p>
                  )}
                </Form.Group>
                <Form.Group className=" w-50 ">
                  <p className="mb-8 fw-semi-bold">Postal Code</p>
                  <Form.Control
                    type="text"
                    id="postalcode"
                    name="postalcode"
                    className="input"
                    onChange={handleChange}
                  />
                  {errors.postalcode && (
                    <p className="error mt-8">{errors.postalcode}</p>
                  )}
                </Form.Group>
              </div>
            </>
          )}
        </div>
        <div className="mb-32">
          <Form.Check
            type="switch"
            id="custom-switch"
            name="IsCardSave"
            className="form-switch"
            label="Make default card"
            onChange={handleChange}
          />
        </div>
        <CircleOrderDetail />
        <div className="d-flex ">
          <Button
            type="button"
            className="btn-secondary mr-12 w-50 text-uppercase"
            onClick={() => {
              if (circleCardInfo.cardId) {
                setcircleCardInfo({ ...circleCardInfo, cardExist: true });
              } else {
                setopenCircleCard(false);
                setchooseCircleCard("");
              }
            }}
          >
            {!circleCardInfo?.cardExist && circleCardInfo?.cardId
              ? "Back"
              : "Cancel"}
          </Button>
          <Button
            type="submit"
            className="btn-primary w-50 text-uppercase"
            disabled={!(isValid && dirty)}
          >
            Buy Now
          </Button>
        </div>
      </Form>
    </>
  );
};
export default CircleCardForm;
