import React from "react";
import Header from "../../components/common/Header";
import { Container, Nav, Tab } from "react-bootstrap";
import CollectionCard from "./CollectionCard";
import { MenuIcon, UserAvator } from "../../constants/images-constants";
import Footer from "../../components/common/Footer";
const ProfileContent = () => {
  return (
    <>
      <Header />
      <section className="profile-section">
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h2 className="d-inline-block">PORSCHE</h2>
          <h4 className="d-inline-block">MOTORSPORT</h4>
        </div>
      </section>
      <div className="profile-header-section mb-40 ">
        <Tab.Container id="left-tabs-example" defaultActiveKey="HIGHLIGHTS">
          <Container>
            <div className=" mt-24 mb-16">
              <div className="d-flex ">
                <div className="profile-user-content  d-flex justify-content-center align-items-center ">
                  <img src={UserAvator} alt="avator" />
                  <div className="ml-24">
                    <h6 className="text-uppercase ">mcspeedster</h6>
                    <p className="text-grey">Joined March, 2021</p>
                  </div>
                </div>
                <div className="ml-32 flex-grow-1 profile-nav-content">
                  <Nav className="">
                    <Nav.Item>
                      <Nav.Link tabClassName="link" eventKey="HIGHLIGHTS">
                        HIGHLIGHTS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="COLLECTIBLES">COLLECTIBLES</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="SETS">SETS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="PACKS">PACKS</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div>
                  <img src={MenuIcon} alt="menu-icon" />
                </div>
              </div>
            </div>
            <div>
              <Tab.Content>
                <Tab.Pane eventKey="HIGHLIGHTS">
                  <CollectionCard />
                </Tab.Pane>
                <Tab.Pane eventKey="COLLECTIBLES">
                  <CollectionCard />
                </Tab.Pane>
                <Tab.Pane eventKey="SETS">
                  <CollectionCard />
                </Tab.Pane>
                <Tab.Pane eventKey="PACKS">
                  <CollectionCard />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Container>
        </Tab.Container>
      </div>

      <Footer />
    </>
  );
};

export default ProfileContent;
