import { type } from "../types";

const initialState = {
  loading: false,
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_LOADER:
      return {
        loading: action.payload,
      };
    default:
      return state;
  }
};
export default loadingReducer;
