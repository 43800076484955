import React from "react";

const CustomCheckbox = (props) => {
  const {handleChain, title, subtitle, selectedChain, icon,selected } = props;
  return (
    <>
      <label
        className="custom-checkbox py-2 pl-16 pr-24 d-flex align-items-center mb-8  pointer"
        onClick={() => handleChain()}
      >
        <img className="mr-16" src={icon} alt="eth-icon" />
        <div className="flex-grow-1">
          <h6 className="mb-8">{title}</h6>
          <p className="opacity-50 ">{subtitle}</p>
        </div>
        <input
          type={"checkbox"}
          checked={selectedChain === selected ? true : false}
        />
      </label>
    </>
  );
};

export default CustomCheckbox;
