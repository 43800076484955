import React from "react";
import PasswordVisibility from "../auth-components/PasswordVisibility";
import { UnviewIcon, ViewIcon } from "../auth-components/PasswordIcon";
import { CHANGE_PASSWORD_VALIDATION } from "../../../helpers/validations/authValidation/authValidationSchema";
import { useNavigate, useParams } from "react-router-dom";
import authServices from "../../../services/authServices/authService";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../redux/actions/loaderActions/loadActions";
import { showAlert } from "../../../redux/actions/alertActions/alertActions";
import { Formik } from "formik";
import AuthFooterContent from "../auth-components/AuthFooterContent";
import { changePasswordInitialValues } from "../../../constants/auth-constants";

const ResetPasswordForm = () => {
  const { code } = useParams();
  var navigator = useNavigate();
  const dispatch = useDispatch();

  const { showpassword, handleShowPassword } = PasswordVisibility();
  const handleSubmit = async (values) => {
    dispatch(setLoader(true));
    const model = { code: code, ...values };
       authServices.ResetPassword(model).then(
        response => {
          dispatch(setLoader(false));
          if (response?.data?.errorCode === 0) {
            dispatch(showAlert(response.data.message, "success", true));
            navigator("/login");
          }
        }
       ).catch(error => {
        dispatch(setLoader(false));
        dispatch(
          showAlert(
            error?.response?.data?.message ?? "something went wrong",
            "danger",
            true
          )
        );
       })
  };

  return (
    <>
      <div className="auth-pages">
        <div className="auth-container">
          <h4 className="text-uppercase mb-8">Reset Password</h4>
          <p className="text-light-grey">
            Mi lorem scelerisque accumsan orci bibendum ultrices ultricies
            suspendisse.
          </p>
          <div className="mt-40">
            <Formik
              validationSchema={CHANGE_PASSWORD_VALIDATION}
              onSubmit={handleSubmit}
              initialValues={{ ...changePasswordInitialValues }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty
              }) => (
                <Form className="formstyle" onSubmit={handleSubmit}>
                  <Form.Group className="mb-24">
                    <p className="mb-8 fw-semi-bold">Password</p>
                    <div className="showicon">
                      <Form.Control
                        type={showpassword.password1 ? "text" : "password"}
                        id="password"
                        name="password"
                        value={values.password}
                        className={`input ${
                          touched.password && errors.password && "input-border"
                        }`}
                        placeholder="Enter Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showpassword.password1 ? (
                        <ViewIcon onClick={() => handleShowPassword(1)} />
                      ) : (
                        <UnviewIcon onClick={() => handleShowPassword(1)} />
                      )}
                    </div>
                    {touched.password && errors.password ? (
                      <p className="error mt-8">{errors.password}</p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-24">
                    <p className="mb-8 fw-semi-bold">Confirm Password</p>
                    <div className="showicon">
                      <Form.Control
                        type={showpassword.password2 ? "text" : "password"}
                        id="confirmpassword"
                        name="confirmpassword"
                        value={values.confirmpassword}
                        className={`input ${
                          touched.confirmpassword && errors.confirmpassword && "input-border"
                        }`}
                        placeholder="Confirm password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showpassword.password2 ? (
                        <ViewIcon onClick={() => handleShowPassword(2)} />
                      ) : (
                        <UnviewIcon onClick={() => handleShowPassword(2)} />
                      )}
                    </div>
                    {touched.confirmpassword && errors.confirmpassword ? (
                      <p className="error mt-8">{errors.confirmpassword}</p>
                    ) : null}
                  </Form.Group>
                  <Button
                    className="btn-primary w-100 text-uppercase mt-16"
                    type="submit"
                    disabled={!(isValid && dirty)}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <AuthFooterContent />
      </div>
    </>
  );
};

export default ResetPasswordForm;
