const requestErrorMessage = {
  SOMETHING_WENT_WRONG: "Something went wrong",
  PAYMENT_FAIL: "Payment can't be proccess",
};
const requestSuccessMessage = {
  SENT_EMAIL_SUCCESSFULLY:
    "Email has been sent to your email address please verify your email",
};

const blockchainMessage = {
  TRANSACTION_SUCCESS: "Transaction completed Successfully",
  TRANSACTION_FAILED: "Transaction Failed",
};
const alertStatus = {
  success: "success",
  error: "danger",
  info: "info",
};

const mintingText = {
  executing: "Executing",
  executingDetail: "Executing transaction on Flow blockchain...",
  revealNft: "REVEAL NFT",
  viewCollecton: " VIEW COLLECTIONS",
};
export {};

export {
  requestErrorMessage,
  requestSuccessMessage,
  blockchainMessage,
  alertStatus,
  mintingText,
};
