export const type = {
  LOGIN_USER: "LOGIN_USER",
  LOGIN_FAIL: "LOGIN_FAIL",
  //ALERTS TYPES
  SHOW_ALERT: "SHOW_ALERT",
  REMOVE_ALERT: "REMOVE_ALERT",

  //LOADING TYPES
  SET_LOADER: "SET_LOADER"
};
