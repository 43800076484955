import EmailConfirmForm from "../../components/auth-pages/email-confirmation/EmailConfirmForm";

const EmailConfrim = () => {
  return (
    <>
      <EmailConfirmForm />
    </>
  );
};

export default EmailConfrim;
