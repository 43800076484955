import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/common/Header";
import FaqsContentConstants from "../../constants/faqs-constants";
import FaqsTable from "./FaqsTable";

const Faqs = () => {
  return (
    <>
      <Header />
      <section className="faqs-section">
        <Container>
          <div className="faqs-container pt-20">
            <h6 className="heading text-black text-center">FAQ'S</h6>
            <div className="pl-20 pr-12">
              <h6 className="text-black">
                {" "}
                {FaqsContentConstants.PERSONAL_PRIVACY}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.PERSONAL_PRIVACY_GOVERNS}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.INFO_GETTING}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.INFO_COLLECTION}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.PERSONAL_INFO}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.PERSONAL_INFO_POLICY}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.SERVICE_PROVIDER}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.SERVICE_PROVIDER_POLICY}
              </p>
              <h6 className="text-black">{FaqsContentConstants.PROTECTION}</h6>
              <p className="text-black">
                {FaqsContentConstants.PROTECTION_POLICY}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.SITE_COOKIES}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.SITE_COOKIES_POLICY}
                <a href={`${FaqsContentConstants.SITE_COOKIES_LINK}`}>
                  {FaqsContentConstants.SITE_COOKIES_LINK}
                </a>
                {FaqsContentConstants.SITE_COOKIES_POLICY_TWO}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.CHILD_PRIVACY}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.CHILD_PRIVACY_POLICY}
              </p>
              <h6 className="text-black">{FaqsContentConstants.PROMO_COMM}</h6>
              <p className="text-black">
                {FaqsContentConstants.PROMI_COMM_POLICY}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.PERSONAL_INFO_UPDATE}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.PERSONAL_INFO_UPDATE_POLICY}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.SITE_SECURITY}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.SITE_SECURITY_POLICY}
              </p>
              <h6 className="text-black">{FaqsContentConstants.LINKS}</h6>
              <p className="text-black">{FaqsContentConstants.LINKS_POLICY}</p>
              <h6 className="text-black">{FaqsContentConstants.BUSSINESS}</h6>
              <p className="text-black">
                {FaqsContentConstants.BUSSINESS_POLICY}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.COMMUNITY_FEATURE}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.COMMUNITY_FEATURE_POLICY}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.INTERNATIONAL_USER}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.INTERNATIONAL_USER_POLICY}
                <a
                  href={`${FaqsContentConstants.INTERNATIONAL_USER_POLICY_LINK}`}
                >
                  {FaqsContentConstants.INTERNATIONAL_USER_POLICY_LINK}{" "}
                </a>
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.EUROPEAN_USER}
                <span className="fw-light">
                  {FaqsContentConstants.EUROPEAN_USER_POLICY}
                </span>
              </h6>
              <p className="text-black">
                <ul className="text-black">
                  <li>{FaqsContentConstants.EUROPEAN_USER_POLICY_ONE}</li>
                  <li>{FaqsContentConstants.EUROPEAN_USER_POLICY_TWO}</li>
                  <li>{FaqsContentConstants.EUROPEAN_USER_POLICY_THREE}</li>
                  <li>{FaqsContentConstants.EUROPEAN_USER_POLICY_FOUR}</li>
                  <li>{FaqsContentConstants.EUROPEAN_USER_POLICY_FIVE}</li>
                  <li>{FaqsContentConstants.EUROPEAN_USER_POLICY_SIX}</li>
                </ul>
                {FaqsContentConstants.EUROPEAN_USER_POLICY_VERIFICATION}
              </p>
              <h6 className="text-black">
                {FaqsContentConstants.CALIFORNIA_RESIDENT}
              </h6>
              <p className="text-black">
                {FaqsContentConstants.CALIFORNIA_RESIDENT_POLICY}
              </p>
              <div>
                <h6 className="text-black mb-24">
                  {FaqsContentConstants.CALIFORNIA_CONSUMER}
                </h6>
                <div className="mr-24 mb-24">
                  <FaqsTable />
                </div>
                <h6 className="text-black">
                  {" "}
                  {FaqsContentConstants.CaLIFORNIA_PRIVACY_RIGHT}
                </h6>
                <h6 className="text-black">
                  {FaqsContentConstants.WHAT_CaLIFORNIA_PRIVACY_RIGHT}
                </h6>
                <div>
                  <ul>
                    <li>
                      <b>{FaqsContentConstants.RIGHT_TO_KNOW}</b>
                      {FaqsContentConstants.RIGHT_TO_KNOW_DESC}
                    </li>
                    <li>
                      <b>{FaqsContentConstants.RIGHT_TO_ACCESS}</b>
                      {FaqsContentConstants.RIGHT_TO_ACCESS_DESC}
                    </li>
                    <li>
                      <b>{FaqsContentConstants.RIGHT_TO_REQUEST} </b>
                      {FaqsContentConstants.RIGHT_TO_REQUEST_DESC}
                    </li>
                    <li>
                      <b>{FaqsContentConstants.DISCRIMINATION}</b>
                      {FaqsContentConstants.DISCRIMINATION_DESC}
                    </li>
                  </ul>
                </div>
                <p className="text-black">
                  <b>{FaqsContentConstants.LIGHT_RIGHT} </b>
                  {FaqsContentConstants.LIGHT_RIGHT_DESC}
                </p>
                <h6 className="text-black">
                  {FaqsContentConstants.SUBMIT_REQUEST}
                </h6>
                <p className="text-black">
                  {FaqsContentConstants.EXERCISE_RIGHT}
                </p>
                <ul>
                  <li>
                    <b>{FaqsContentConstants.REQUEST_TO_ACCESS}</b>
                  </li>
                  <li>
                    <b>{FaqsContentConstants.REQUEST_TO_KNOW}</b>
                  </li>
                  <li>
                    <b>{FaqsContentConstants.REQUEST_TO_DEL}</b>
                  </li>
                </ul>
                <h6 className="text-black">{FaqsContentConstants.CONTACT_US}</h6>
                <ul>
                  <li>
                    <b>{FaqsContentConstants.POSTAL_ADDRESS}</b>:
                    {FaqsContentConstants.POSTAL_ADDRESS_DETAIL}
                  </li>
                  <li>
                    <b>{FaqsContentConstants.EMAIL}</b>
                    {FaqsContentConstants.EMAIL_ADDR}
                  </li>
                  <li>
                    <b>{FaqsContentConstants.PHONE}</b>
                  </li>
                </ul>
                <p className="text-black">
                  {FaqsContentConstants.VERIFY_DETAIL}
                </p>
                <p className="mt-24 text-black">{FaqsContentConstants.TAG}</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Faqs;
