import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions/alertActions";
import { setLoader } from "../../redux/actions/loaderActions/loadActions";
import { useNavigate } from "react-router-dom";
import {
  alertStatus,
  requestErrorMessage
} from "../../constants/messages-constants";

const useRequesHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const requestHandler = (payload, redirectUrl) => {
    dispatch(setLoader(false));
    if (payload?.data?.errorCode === 0) {
      dispatch(showAlert(payload?.data.message, alertStatus.success, true));
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    } else if (payload?.response?.data?.message ?? payload) {
      dispatch(
        showAlert(
          payload?.response?.data?.message ?? payload,
          alertStatus.error,
          true
        )
      );
    } else {
      dispatch(
        showAlert(
          requestErrorMessage.SOMETHING_WENT_WRONG,
          alertStatus.error,
          true
        )
      );
    }
  };
  return {
    requestHandler
  };
};

export default useRequesHandler;
