import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { logoutAction } from "../../redux/actions/loginActions/loginActions";
import { authenticate, unauthenticate } from "@onflow/fcl";
import { HeaderLogo } from "../../constants/images-constants";
import useRequesHandler from "../../custom-hooks/request-handler-hook";
import { setLoader } from "../../redux/actions/loaderActions/loadActions";
import { loginAction } from "./../../redux/actions/loginActions/loginActions";
import { heroSection } from "../../constants/home-page-constants";

const Header = () => {
  const { requestHandler } = useRequesHandler();
  const { walletAddr } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutAction("logout successfully"))
      .then((response) => {
        unauthenticate();
        requestHandler(response);
      })
      .catch((error) => {
        requestHandler(error);
      });
  };

  const dapperSignInHandler = async () => {
    dispatch(setLoader(true));
    const authResult = await authenticate();
    if (authResult?.addr) {
      const payload = { WalletAddress: authResult?.addr };
      dispatch(loginAction(payload))
        .then((response) => {
          requestHandler(response);
        })
        .catch((error) => {
          requestHandler(error);
        });
    } else {
      requestHandler("User declined to login");
    }
  };
  return (
    <>
      <header className="header ">
        <nav className="navbar p-0 ">
          <div className="container">
            <Link to="/">
              <img src={HeaderLogo} className="img-fluid" alt="logo" />
            </Link>

            <div className="header-navigation">
              <ul className="d-flex align-items-center">
                <li id="signin">
                  <a
                    className="header-nav-link"
                    href={heroSection.IMSA_BLOG_URL}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    BLOG
                  </a>
                </li>
                <li id="signin">
                  <a
                    className="header-nav-link"
                    href={heroSection.IMSA_DISCORD_URL}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    DISCORD
                  </a>
                </li>
                <li id="signin">
                  <Link className="header-nav-link mr-40" to="#">
                    CONTACT
                  </Link>
                </li>

                {!walletAddr && (
                  <>
                    <li id="signin">
                      <Link
                        className="header-nav-link"
                        to="#"
                        onClick={dapperSignInHandler}
                      >
                        LOGIN
                      </Link>
                    </li>
                    <li id="signin">
                      <Link
                        className="header-nav-link"
                        to="#"
                        onClick={dapperSignInHandler}
                      >
                        SIGN UP
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {walletAddr && (
              <div className="header-dropdown">
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={
                    walletAddr?.substring(0, 6) +
                    "..." +
                    walletAddr?.replace(/.(?=.{4})/g, "")
                  }
                  menuVariant="dark"
                  className="dropdown"
                >
                  <NavDropdown.Item href="/purchase">Purchase</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={handleLogout}>
                    logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            )}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
