import { type } from "../../types";
import authServices from "./../../../services/authServices/authService";

export const loginAction = (data) => async (dispatch) => {
  return authServices
    .Login(data)
    .then((response) => {
      if (response?.data?.errorCode === 0) {
        dispatch({
          type: type.LOGIN_USER,
          payload: response?.data?.payload //response.data.payload,
        });
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch({
        type: type.LOGIN_FAIL,
        payload: error?.response?.data?.message ?? "something went wrong"
      });
      return Promise.reject(error);
    });
};

export const logoutAction = (message) => (dispatch) => {
  return authServices
    .LogOut()
    .then((response) => {
      dispatch({
        type: type.LOGIN_FAIL,
        payload: message
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch({
        type: type.LOGIN_FAIL,
        payload: message
      });
      return Promise.reject(error);
    });
};
