import React from "react";

const CustomRadioButton = (props) => {
  const { handlePayment, value, selectPaymentOption, label, icon } =
    props;

  return (
    <>
      <label
        className={`custom-radio-btn d-flex align-items-center w-100 justify-content-center pointer ${
          selectPaymentOption === value &&  "active"
        } w-100`}
        onClick={() => handlePayment()}
      >
        <input type="radio" value={value} />
        <img src={icon} alt="card-icon" className="mr-12 " />
        <p> {label}</p>
      </label>
    </>
  );
};

export default CustomRadioButton;
