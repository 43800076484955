import React, { useEffect } from "react";
import ModalPopup from "../../common/ModalPopup/ModalPopup";
import { useSelector } from "react-redux";
import CirclePaymentService from "../../../services/circlePaymentService/circlePaymentService";
import PaymentLoader from "./circle-components/PaymentLoader";

const CirclePaymentModal = ({
  openPaymentModal,
  setopenPaymentModal,
  circleCardInfo,
  setopenTransactionModal,
  setcircleCardInfo
}) => {
  const { email, userid } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (openPaymentModal) {
      hanldeCirclePayment();
    }
    // eslint-disable-next-line
  }, [openPaymentModal]);

  const hanldeCirclePayment = () => {
    
    const data = {
      id:  circleCardInfo.cardId,
      amount: "203",
      currency: "USD",
      keyId: circleCardInfo.keyId,
      encryptedData:  circleCardInfo.encryptedData,
      email: email,
      userId: userid,
    };

    CirclePaymentService.createCirclePayment(data)
      .then((response) => {
        if (response.data.errorCode === 0) {
          setcircleCardInfo({circleCardInfo})
          setopenPaymentModal(false);
          setopenTransactionModal({ show: true, status: "success" });
        } else {
          setopenPaymentModal(false);
          setopenTransactionModal({ show: true, status: "failed" });
        }
      })
      .catch((e) => {
        setopenPaymentModal(false);
        setopenTransactionModal({ show: true, status: "failed" });
      });
  };
  return (
    <>
      <ModalPopup
        show={openPaymentModal}
        onHide={() => setopenPaymentModal(false)}
        modelheading={""}
        showheader={"true"}
        dialogClassName="modal-dialog-centered mwidth-72"
        centerclassname=""
        modelbody={[
          <>
            <div className="mb-40">
              <h4 className="mb-8">Processing Payment</h4>
              <p className="text-light-grey">
                Amet diam at hendrerit quam nullam iaculis turpis nisl varius.
              </p>
            </div>
            <PaymentLoader />
          </>,
        ]}
      />
    </>
  );
};

export default CirclePaymentModal;
