import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/screen.scss";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "./helpers/loader/Loader";
import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Emailvalidation from "./pages/auth/Emailvalidation";
import ForgotPassword from "./pages/auth/ForgotPassword";
import EmailConfirm from "./pages/auth/EmailConfirmation";
import GetStarted from "./pages/auth/GetStarted";
import ResetPasswordForm from "./components/auth-pages/reset-password-page/ResetPasswordForm";
import ToastMessage from "./helpers/alerts/ToastMessage";
import Purchase from './pages/purchase/index';
import Faqs from './pages/faqs/Faqs';
import ClaimNft from './pages/claim/ClaimNft';
import MintingNft from './pages/minting/Minting';
import RevealMintNft from "./pages/minting/RevealMintNft";
import Profile from "./pages/profile/Profile";

const App = () => {
  return (
    <>
      <Loader />
      <ToastMessage />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/email-validate" element={<Emailvalidation />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:code"
            element={<ResetPasswordForm />}
          />
          <Route path="/confirm-email/:code" element={<EmailConfirm />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/claim" element={<ClaimNft />} />
          <Route path="/minting" element={<MintingNft />} />
          <Route path="/reveal" element={<RevealMintNft />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
