import React from "react";
import { MintingLoader } from "../../constants/images-constants";
import { mintingText } from "../../constants/messages-constants";

const MintingNft = () => {
  return (
    <>
      <img src={MintingLoader} alt="loader" className="spiner" />
      <div className="bottom-content text-center">
        <h6>{mintingText.executing}</h6>
        <p className="text-grey ">{mintingText.executingDetail}</p>
      </div>
    </>
  );
};

export default MintingNft;
