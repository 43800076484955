import * as Yup from "yup";

const CLAIM_VALIDATION = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name Required")
    .min(2, "First Name must be minimum 2 characters")
    .max(50, "First Name must be maximun 50 characters")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
  lastName: Yup.string()
    .required("Last Name Required")
    .min(2, "Last Name must be minimum 2 characters")
    .max(50, "Last Name must be maximun 50 characters")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
  email: Yup.string().email("Valid Email Required").required("Email Required"),
  class: Yup.string().required("class is Required")
});

export { CLAIM_VALIDATION };
