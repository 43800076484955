import React from "react";
import { useSelector } from "react-redux";
import { ImsaLoader } from "../../constants/images-constants";

const Loader = () => {
  const { loading } = useSelector((state) => state.loadingReducer);
  return (
    <>
      <div
        className="sweet-loading"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 999999,
          width: "100vw",
          height: "100vh",
          backgroundColor: "#000",
          opacity: 0.5,
          display: loading ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <img
          src={ImsaLoader}
          style={{ display: loading ? "block" : "none" }}
          alt="ImsaLoader"
        />
        {/* <BeatLoader 
         color={"#FFFFFF"}
         loading={loading}
         size={15}
         speedMultiplier="1"
         css={override}
      /> */}
      </div>
    </>
  );
};

export default Loader;
