import { Formik } from "formik";
import React, { useState } from "react";
import { FORGOT_PASSWORD_VALIDATION } from "../../../helpers/validations/authValidation/authValidationSchema";
import authServices from "../../../services/authServices/authService";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoader } from "./../../../redux/actions/loaderActions/loadActions";
import { showAlert } from "./../../../redux/actions/alertActions/alertActions";
import AuthFooterContent from "../auth-components/AuthFooterContent";
import { forgotPasswordInitialvalues } from "../../../constants/auth-constants";
import { requestErrorMessage } from "../../../constants/messages-constants";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const [activeBtn, setactiveBtn] = useState(false)

  const handleSubmit = async (values) => {
    dispatch(setLoader(true));
    authServices
      .ForgotPassword(values)
      .then((response) => {
        dispatch(setLoader(false));
        if (response?.data?.errorCode === 0) {
          dispatch(showAlert(response.data.message, "success", true));
          setactiveBtn(true)
          setTimeout(() => {
            setactiveBtn(false);
          }, 100000);
        }
      })
      .catch((error) => {
        dispatch(setLoader(false));
        dispatch(
          showAlert(
            error?.response?.data?.message ?? requestErrorMessage.SOMETHING_WENT_WRONG,
            "danger",
            true
          )
        );
        setactiveBtn(false)
      });
  };

  return (
    <>
      <div className="auth-pages">
        <div className="auth-container">
          <h4 className="text-uppercase mb-8">Forgot Password</h4>
          <p className="text-light-grey">
            Mi lorem scelerisque accumsan orci bibendum ultrices ultricies
            suspendisse.
          </p>
          <div className="mt-40">
            <Formik
              validationSchema={FORGOT_PASSWORD_VALIDATION}
              onSubmit={handleSubmit}
              initialValues={{ ...forgotPasswordInitialvalues }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <Form className="formstyle" onSubmit={handleSubmit}>
                  <Form.Group className="mb-24">
                    <p className="mb-8 fw-semi-bold">Email</p>

                    <Form.Control
                      type="text"
                      id="email"
                      name="email"
                      className={`input ${
                        touched.email && errors.email && "input-border"
                      }`}
                      placeholder="Enter Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {touched.email && errors.email ? (
                      <p className="error mt-8">{errors.email}</p>
                    ) : null}
                  </Form.Group>
                  <Button
                    className="btn-primary w-100 text-uppercase mt-16"
                    type="submit"
                    disabled={activeBtn}
                  >
                    Verify Email
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <AuthFooterContent />
      </div>
    </>
  );
};

export default ForgotPasswordForm;
