import React from "react";
import { Row, Col } from "react-bootstrap";
import { CollectionIcon } from "../../constants/images-constants";

const CollectionCard = () => {
  return (
    <>
      <Row className="text-center">
        <Col md={12}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, ].map((index) => (
            <img
              src={CollectionIcon}
              key={index}
              alt="imsa"
              className="mr-24 mt-24"
            />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default CollectionCard;
