import React from 'react'
import GetStartedForm from './../../components/auth-pages/get-started-page/GetStartedForm';

const GetStarted = () => {
  return (
    <>
    <GetStartedForm />
    </>
  )
}

export default GetStarted