import ModalPopup from "../../common/ModalPopup/ModalPopup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../redux/actions/alertActions/alertActions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CirclePaymentOptions from "./CirclePaymentOptions";


const SendUsdModal = ({
  openSendUsdModal,
  setOpenSendUsdModal,
  walletAddress,
  setopenCircleCard,
  selectPaymentOption,
  setselectPaymentOption,
  setopenSelectChainModal,
  setopenCheckUsdStatusModal,
  qrImage,
  selectedChain
}) => {
  const copyAddress = useRef();
  const dispatch = useDispatch();
  const [copyaddress, setCopyAddress] = useState("copy");
  const copyText = async (event) => {
    event.preventDefault();
    await navigator.clipboard
      .writeText(copyAddress.current.getAttribute("value"))
      .then(() => {
        setCopyAddress("copied");
        dispatch(showAlert("Text Copied", "success", true));
      })
      .catch(() => {
        console.log("not copied");
      });
  };

 
 
  return (
    <>
      <ModalPopup
        show={openSendUsdModal}
        onHide={() => setOpenSendUsdModal(false)}
        modelheading={""}
        showheader={"true"}
        dialogClassName={`modal-dialog-centered`}
        // centerclassname={openCheckUsdStatusModal}
        scrollable={true}
        modelbody={[
          <div>
            <div className="mb-40">
              <h4 className="text-uppercase">Get your Platinum access</h4>
              <p className="text-light-grey">
                Amet diam at hendrerit quam nullam iaculis turpis nisl varius.
              </p>
            </div>
            <CirclePaymentOptions
              setopenCircleCard={setopenCircleCard}
              setopenSelectChainModal={setopenSelectChainModal}
              selectPaymentOption={selectPaymentOption}
              setselectPaymentOption={setselectPaymentOption}
            />
            <div className="text-center mt-32 mb-24">
              <h4 className="mb-16 text-uppercase">{selectedChain} USDC</h4>
              <h6 className="text-light-grey fw-normal">
                Transfer the payment by scanning the QR code or directly to the
                address below
              </h6>
            </div>
            <div className="text-center mb-24">
              <p>
                Address is valid for:<span className="fs-20">19:49</span>
              </p>
            </div>
            <div className="text-center mb-24">
              <div className="p-16 d-inline-block " >
              <img src={qrImage} alt="gains" />
              </div>
            </div>
            <div className="address-box d-flex justify-content-between mb-24">
              <h6
                ref={copyAddress}
                value={walletAddress}
                className="maxw-350  overflow-hidden"
              >
                {walletAddress}
              </h6>
              <p className="text-purple fw-bold pointer" onClick={copyText}>
                {copyaddress}
              </p>
            </div>
            <p className="text-light-grey text-center mb-40">
              Having problem? Check out our{" "}
              <Link to="/" className="navigation-link">
                help center.
              </Link>
            </p>
            <div className="d-flex justify-content-center">
              <Button
                type="button"
                className="btn-secondary mr-12 w-100 text-uppercase"
                onClick={() => {
                  setOpenSendUsdModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn-primary w-100 text-uppercase"
                onClick={() => {
                  setopenSelectChainModal(false);
                  setopenCheckUsdStatusModal(true);
                  setselectPaymentOption("pay-with-card");
                }}
              >
                Transfer Completed
              </Button>
            </div>
          </div>,
        ]}
      />
    </>
  );
};

export default SendUsdModal;
