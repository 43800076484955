

import { CardPayIcon, CryptoPayIcon } from "../../../constants/images-constants";
import CustomRadioButton from "./../../common/CustomRadioButton";

const CirclePaymentOptions = (props) => {
  const {
    selectPaymentOption,
    setselectPaymentOption,
    setopenSelectChainModal,
    setopenCircleCard,
  } = props;
  return (
    <>
      <div className="d-flex mb-16">
        <div className="mr-8">
          <CustomRadioButton
            handlePayment={() => {
              setselectPaymentOption("pay-with-card");
              setopenCircleCard(true)
              setopenSelectChainModal(false)
              ;
            }}
            value="pay-with-card"
            selectPaymentOption={selectPaymentOption}
            label={"Pay with Credit/Debit"}
            icon={CardPayIcon}
          />
        </div>
        <div className="flex-grow-1">
          <CustomRadioButton
            handlePayment={() => {
              setselectPaymentOption("pay-with-USDC");
              setopenSelectChainModal(true)
              setopenCircleCard(false)
              ;
            }}
            value="pay-with-USDC"
            selectPaymentOption={selectPaymentOption}
            label={"Pay with USDC"}
            icon={CryptoPayIcon}
            
          />
        </div>
      </div>
    </>
  );
};

export default CirclePaymentOptions;
