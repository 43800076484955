import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = "https://imsa-backend-dev.troonportal.com/api/";

axiosClient.interceptors.request.use(
  (request) => {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// RESPONSE INTERCEPTOR
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default axiosClient;
