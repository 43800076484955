import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../redux/actions/loaderActions/loadActions";
import { showAlert } from "../../../redux/actions/alertActions/alertActions";
import authServices from "../../../services/authServices/authService";
import { requestErrorMessage } from "../../../constants/messages-constants";

const EmailValidationForm = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { email   } = state || {};
  const [emailStatus, setemailStatus] = useState('');

  const handleResend =  (e) => {
    e.preventDefault();
    setemailStatus("Sending Email ...");
     authServices.ForgotPassword({ email: email }).then(
      response => {
        if (response?.data?.errorCode === 0) {
          dispatch(showAlert(response.data.message, "success", true));
          setemailStatus("SENT!");
          setTimeout(() => {
            setemailStatus("");
          }, 10000000);
        }
      }
     ).catch(error => {
      dispatch(setLoader(false));
      dispatch(
        showAlert(
          error?.response?.data?.message ?? requestErrorMessage.SOMETHING_WENT_WRONG,
          "danger",
          true
        )
      );
      setemailStatus("");
     })
  };
  return (
    <>
      <div className="auth-pages">
        <div className="auth-container">
          <h4 className="text-uppercase mb-40">Check your Email</h4>
          <p className="text-grey mb-24 ">
            We have sent a verification email to:{" "}
          </p>
          <div className="auth-email-validate-field  mb-24">
            <h6>{email}</h6>
          </div>
          <p className="text-grey mb-24">
            Verify your email to complete the account creation proccess. The
            verification link will expire in 24 hours.
          </p>
          <Button
            className="btn-primary w-100 mt-16 mb-40"
            onClick={handleResend}
            type="button"
            disabled={emailStatus !== ""}
          >
            {emailStatus === "" ? "Resend Verification Email" : emailStatus}
          </Button>
          <p className="text-light-grey">
            Go back to{" "}
            <Link to="/" className="navigation-link">
              IMSAFastlane.com
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default EmailValidationForm;
