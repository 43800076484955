import React from "react";
import { Button } from "react-bootstrap";
import AuthFooterContent from "../auth-components/AuthFooterContent";
import { Logo } from "../../../constants/images-constants";
import { authTextConstant } from "../../../constants/auth-constants";

const GetStartedForm = () => {
  
  return (
    <>
      <div className="auth-pages">
        <div className="auth-container">
          <div className="mb-56 text-center">
            <img className="mb-24" src={Logo} alt="imsa-fastlane" />
            <p className="text-light-grey">
              {authTextConstant.GET_STARTED_TEXT}
            </p>
          </div>
          <Button
            className="btn-primary w-100 mb-32"
      
          >
            Login
          </Button>
        </div>
        <AuthFooterContent />
      </div>
    </>
  );
};

export default GetStartedForm;
