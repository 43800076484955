import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";

const PaymentLoader = () => {
  return (
    <>
     <div className="p-32 text-center">
           <BeatLoader 
         color={"#FFFFFF"}
         loading={true}
         size={15}
         speedMultiplier="1"
         
      />
           </div>
    </>
  )
}

export default PaymentLoader