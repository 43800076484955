import React,{useState} from 'react'
import { benefitSection } from '../../constants/home-page-constants'
import { Row ,Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import externalService from './../../services/externalService/externalService';
import CirclePaymentService from './../../services/circlePaymentService/circlePaymentService';
import CreateCircleCard from './../Modals/circlePaymentModals/CreateCircleCard';
import CircleSelectUsdChain from './../Modals/circlePaymentModals/CircleSelectUsdChain';
import CirclePaymentModal from './../Modals/circlePaymentModals/CirclePaymentModal';
import TransactionStatusModal from './../Modals/circlePaymentModals/TransactionStatusModal';
import CheckUsdStatusModal from './../Modals/circlePaymentModals/CheckUsdStatusModal';



const PurchaseSection = () => {

  const navigator = useNavigate();
  const { walletAddr, userid } = useSelector((state) => state.loginReducer);
  const [openCircleCard, setopenCircleCard] = useState(false);
  const [openPaymentModal, setopenPaymentModal] = useState(false);
  const [openSelectChainModal, setopenSelectChainModal] = useState(false);
  const [countryCode, setcountryCode] = useState([]);
  const [openTransactionModal, setopenTransactionModal] = useState({
    show: false,
    status: "",
  });
  const [circleCardInfo, setcircleCardInfo] = useState({
    keyId: "",
    encryptedData: "",
    cardId: "",
    cardInfo: "",
    cardExist: false,
  });
  const [selectPaymentOption, setselectPaymentOption] =
    useState("pay-with-card");
  const [openCheckUsdStatusModal, setopenCheckUsdStatusModal] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const handlePaymentModal = async () => {
    if (walletAddr) {
      await CirclePaymentService.getUserCircleCardDetail(userid)
        .then((response) => {
          if (response.data.payload.length > 0) {
            setcircleCardInfo({
              ...circleCardInfo,
              cardId: response.data.payload[0].cardId,
              encryptedData: response.data.payload[0].cardToken,
              cardInfo: response.data.payload[0],
              keyId: response.data.payload[0].key,
              cardExist: true,
            });
            setopenCircleCard(true);
          } else {
            setopenCircleCard(true);
          }
        })
        .catch((e) => {
          setopenCircleCard(true);
        });
      externalService
        .getCountryCode()
        .then((response) => {
          setcountryCode(response?.data);
        })
        .catch((error) => {
          setcountryCode([]);
        });
    } else {
      navigator("/get-started");
    }
  };

  return (
    <>
    <Header />
    <section className="benefit-section">
        <Container>
          <div className="p-90 d-flex justify-content-center ">
            
          </div>
          <div className="whitelist">
            <h3 className="whitelist-heading">
              {benefitSection.UNLOCK__WORLD_OF_BENEFITS}
            </h3>
            <Row>
              <Col md={7} className="d-flex justify-content-center pt-40">
                <p className="fs-20 lh-36 w-520 ">
                  {benefitSection.BONUS_FOR_JOINING_TEXT}
                  <Link to={"/"} className="navigation-link">
                    {benefitSection.BOUN_FOR_JOINING_LINK}
                  </Link>
                  <br />
                  <br />
                  {benefitSection.TRUST_US_TEXT}
                </p>
              </Col>
              <Col md={5} className="mt-24">
                  
                    <Button className="btn-hero w-100 bg-red mb-40" onClick={handlePaymentModal} >
                      Pay Now
                    </Button>
               
                 
                
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      
      <CreateCircleCard
        circleCardInfo={circleCardInfo}
        setcircleCardInfo={setcircleCardInfo}
        openCircleCard={openCircleCard}
        setopenCircleCard={setopenCircleCard}
        openPaymentModal={openPaymentModal}
        setopenPaymentModal={setopenPaymentModal}
        setopenSelectChainModal={setopenSelectChainModal}
        selectPaymentOption={selectPaymentOption}
        setselectPaymentOption={setselectPaymentOption}
        countryCode={countryCode}
      />
      {openSelectChainModal && (
        <CircleSelectUsdChain
          openSelectChainModal={openSelectChainModal}
          setopenSelectChainModal={setopenSelectChainModal}
          setopenCircleCard={setopenCircleCard}
          selectPaymentOption={selectPaymentOption}
          setselectPaymentOption={setselectPaymentOption}
          setopenCheckUsdStatusModal={setopenCheckUsdStatusModal}
          setWalletAddress={setWalletAddress}
          walletAddress={walletAddress}
        />
      )}

      {openPaymentModal && (
        <CirclePaymentModal
          openPaymentModal={openPaymentModal}
          setopenPaymentModal={setopenPaymentModal}
          setopenCircleCard={setopenCircleCard}
          circleCardInfo={circleCardInfo}
          setcircleCardInfo={setcircleCardInfo}
          setopenTransactionModal={setopenTransactionModal}
        />
      )}
      {openTransactionModal && (
        <TransactionStatusModal
          openTransactionModal={openTransactionModal}
          setopenTransactionModal={setopenTransactionModal}
        />
      )}
      {openCheckUsdStatusModal && (
        <CheckUsdStatusModal
          openCheckUsdStatusModal={openCheckUsdStatusModal}
          setopenCheckUsdStatusModal={setopenCheckUsdStatusModal}
          setopenTransactionModal={setopenTransactionModal}
          walletAddress={walletAddress}
        />
      )}
    </>
  )
}

export default PurchaseSection