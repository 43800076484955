import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DiscordIcon, InstagramIcon, TwitterIcon } from "../../constants/images-constants";

const Footer = () => {
  return (
    <>
      <section className="footer-section">
        <Container>
          <Row>
            <Col md={8}>
              <div className="mb-24">
                <Link to={"/"} className="mr-40 text-light-grey fs-14">
                  Privacy Policy
                </Link>
                <Link to="/" className="text-light-grey fs-14 mr-40">
                  Terms of Service
                </Link>
                <Link to="/faqs" className="text-light-grey fs-14">
                  Faqs
                </Link>
              </div>
              <div>
                <Link className="mr-24 " to={"/"}>
                  <img src={DiscordIcon} alt="social-icon" />
                </Link>
                <Link className="mr-24" to={"/"}>
                  <img src={InstagramIcon} alt="social-icon" />
                </Link>
                <Link to={"/"}>
                  <img src={TwitterIcon} alt="social-icon" />
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <p>© 2022 International Motor Sports Association, LLC.</p>
              <p>© 2022 Minti Labs, Inc.</p>
              <p className="fs-12">
                Officially Licensed Partner of the International Motor Sports
                Association © [2022]
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
