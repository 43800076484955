import React from "react";
import PasswordVisibility from "../auth-components/PasswordVisibility";
import { UnviewIcon, ViewIcon } from "../auth-components/PasswordIcon";
import { Formik } from "formik";
import authServices from "../../../services/authServices/authService";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../redux/actions/alertActions/alertActions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SIGNUP_VALIDATION } from "../../../helpers/validations/authValidation/authValidationSchema";

import { Form, Button } from "react-bootstrap";
import { setLoader } from "./../../../redux/actions/loaderActions/loadActions";
import AuthFooterContent from "../auth-components/AuthFooterContent";
import { signUpInitialValues } from "../../../constants/auth-constants";
import { requestErrorMessage, requestSuccessMessage } from "../../../constants/messages-constants";

const SignUpForm = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { state } = useLocation();
  const { email } = state || {};
  
  const { showpassword, handleShowPassword } = PasswordVisibility();

  const handleSubmit = async (values) => {
    dispatch(setLoader(true));
    authServices
      .SignUp(values, email)
      .then((response) => {
        
        if (response?.data?.errorCode === 0) {
          dispatch(setLoader(false));
          dispatch(
            showAlert(
              requestSuccessMessage.SENT_EMAIL_SUCCESSFULLY,
              "success",
              true
            )
          );
          navigator("/email-validate", { state: { email } });
        }
        
      })
      .catch((error) => {
        dispatch(setLoader(false));
        dispatch(
          showAlert(
            error?.response?.data?.message ?? requestErrorMessage.SOMETHING_WENT_WRONG,
            "danger",
            true
          )
        );
      });
  };

  return (
    <>
      <div className="auth-pages">
        <div className="auth-container">
          <h4 className="text-uppercase mb-8">Sign up with email</h4>
          <p className="text-light-grey">
            Mi lorem scelerisque accumsan orci bibendum ultrices ultricies
            suspendisse.
          </p>
          <div className="mt-40">
            <Formik
              validationSchema={SIGNUP_VALIDATION}
              onSubmit={handleSubmit}
              initialValues={{ ...signUpInitialValues }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                dirty,
                isValid,
              }) => (
                <Form className="formstyle" onSubmit={handleSubmit}>
                  <Form.Group className="mb-24">
                    <p className="mb-8 fw-semi-bold">First Name</p>
                    <Form.Control
                      type="text"
                      id="firstname"
                      name="firstName"
                      className={`input ${
                        touched.firstName && errors.firstName && "input-border"
                      }`}
                      placeholder="John"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.firstName && errors.firstName ? (
                      <p className="error mt-8">{errors.firstName}</p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-24">
                    <p className="mb-8 fw-semi-bold">Last Name</p>
                    <Form.Control
                      type="text"
                      id="lastname"
                      name="lastName"
                      className={`input ${
                        touched.lastName && errors.lastName && "input-border"
                      }`}
                      placeholder="Doe"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.lastName && errors.lastName ? (
                      <p className="error mt-8">{errors.lastName}</p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-24">
                    <p className="mb-8 fw-semi-bold">Password</p>
                    <div className="showicon">
                      <Form.Control
                        type={showpassword.password1 ? "text" : "password"}
                        id="password"
                        name="password"
                        className={`input ${
                          touched.password && errors.password && "input-border"
                        }`}
                        placeholder="Minimum of 8 characters"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showpassword.password1 ? (
                        <ViewIcon onClick={() => handleShowPassword(1)} />
                      ) : (
                        <UnviewIcon onClick={() => handleShowPassword(1)} />
                      )}
                    </div>
                    {touched.password && errors.password ? (
                      <p className="error mt-8">{errors.password}</p>
                    ) : null}
                  </Form.Group>
                  <Button
                    className="btn-primary w-100 text-uppercase mt-16 mb-40"
                    type="submit"
                    disabled={!(dirty && isValid)}
                  >
                    Sign up with Email
                  </Button>
                  <div>
                    <p className="text-light-grey">
                      Already have an account?{" "}
                      <Link to="/login" className="navigation-link">
                        Log In
                      </Link>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <AuthFooterContent />
      </div>
    </>
  );
};

export default SignUpForm;
