import { ClaimInitialValues } from "../../constants/claim-constants";
import FormikUtil from "../../helpers/utils/formik/FormiklUtil";
import { CLAIM_VALIDATION } from "../../helpers/validations/claimValidation";
import ClainNftForm from "./ClainNftForm";
import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions/alertActions";
import { alertStatus } from "../../constants/messages-constants";
import { setLoader } from "../../redux/actions/loaderActions/loadActions";
import DapperTransactions from "../../blockchains-transactions-and-scripts/blockchain-transactions";
import script from "../../blockchains-transactions-and-scripts/blockchain-scripts";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ClaimNftContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState({
    variant: "CLAIM",
    showMinting: false
  });
  const HandleClaimInformation = async (values) => {
    let interval;
    try {
      setStatus({
        ...status,
        variant: "CONNECTING TO DAPPER ...",
        status: true
      });
      interval = setTimeout(() => {
        navigate("/minting");
      }, 2000);
      const transactionResponse = await DapperTransactions(
        script.HelloWorldTransaction
      );

      if (transactionResponse?.error === false) {
        clearTimeout(interval);
        navigate("/reveal");
      } else {
        let errorMsg = transactionResponse?.message;
        if (typeof errorMsg === "object") {
          errorMsg = errorMsg?.message;
        }
        dispatch(showAlert(errorMsg, alertStatus.error, true));
        clearTimeout(interval);
        setStatus({ ...status, variant: "CLAIM", showMinting: false });
        navigate("/claim");
      }
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(showAlert(error?.message, alertStatus.error, true));
      clearTimeout(interval);
      setStatus({ ...status, variant: "CLAIM", showMinting: false });
      navigate("/claim");
    }
  };

  const formik = FormikUtil(
    ClaimInitialValues,
    CLAIM_VALIDATION,
    HandleClaimInformation
  );

  return (
    <>
      <ClainNftForm formik={formik} status={status} />
    </>
  );
};

export default ClaimNftContent;
