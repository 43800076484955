import React from "react";
import MintingNft from "./MintingNft";
//import RevealNft from "./RevealNft";
//import RevealView from "./RevealView";

const MintingContent = () => {

    
    
  return (
    <>
      <div className="minting-section">
        <MintingNft />
      </div>
    </>
  );
};

export default MintingContent;
