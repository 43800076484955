import React, { useEffect } from "react";
import ModalPopup from "../../common/ModalPopup/ModalPopup";
import { Button } from "react-bootstrap";
import PaymentLoader from "./circle-components/PaymentLoader";
import CirclePaymentService from "../../../services/circlePaymentService/circlePaymentService";

const CheckUsdStatusModal = ({
  openCheckUsdStatusModal,
  setopenCheckUsdStatusModal,
  setopenTransactionModal,
  walletAddress,
}) => {
  useEffect(() => {
    CirclePaymentService.getUsdcPaymentConfirmation(walletAddress)
      .then((response) => {
        if (response?.data?.errorCode === 0) {
          setopenCheckUsdStatusModal(false);
          setopenTransactionModal({ show: true, status: "success" });
        }
      })
      .catch((error) => {
        setopenCheckUsdStatusModal(false);
        setopenTransactionModal({ show: true, status: "failed" });
      });

    // eslint-disable-next-line
  }, []);

  console.log("data", walletAddress);
  return (
    <>
      {" "}
      <ModalPopup
        show={openCheckUsdStatusModal}
        onHide={() => setopenCheckUsdStatusModal(false)}
        modelheading={""}
        showheader={"true"}
        dialogClassName="modal-dialog-centered mwidth-72"
        centerclassname=""
        modelbody={[
          <div>
            <div className="mb-40">
              <h4 className="text-uppercase">Processing Payment </h4>
              <p className="text-light-grey">
                Amet diam at hendrerit quam nullam iaculis turpis nisl varius.
              </p>
            </div>
            <PaymentLoader />
            <div className="mt-90 mb-40 text-center">
              <h6>Verifying transaction status</h6>
            </div>
            <div className="pt-40 pb-40 text-center">
              <p className="text-light-grey">
                You will be notified when the transaction has been completed via
                email. You can close this modal now.
              </p>
            </div>
            <Button
              className="btn-primary text-uppercase w-100"
              onClick={() => {
                setopenCheckUsdStatusModal(false);
                setopenTransactionModal({ show: true, status: "success" });
              }}
            >
              Done
            </Button>
          </div>,
        ]}
      />
    </>
  );
};

export default CheckUsdStatusModal;
