import {
  getRequest,
  postRequest
} from "../../helpers/utils/axios/axiosMethods";

const EmailExist = (email) => {
  return new Promise((resolve, reject) => {
    postRequest("account/email-exist", email)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const SignUp = (signupModel, email) => {
  return new Promise((resolve, reject) => {
    postRequest("account/sign-up", {
      ...signupModel,
      email: email
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const Login = (loginModel) => {
  return new Promise((resolve, reject) => {
    postRequest("account/dapper-sign-up", loginModel)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const SignInUsingGoogle = (googleLoginModel) => {
  return new Promise((resolve, reject) => {
    postRequest("Account/google-sign-in", googleLoginModel)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const ForgotPassword = (forgotPasswordModel) => {
  return new Promise((resolve, reject) => {
    postRequest("account/forgot-password", forgotPasswordModel)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const ResetPassword = (resetPasswordModel) => {
  return new Promise((resolve, reject) => {
    postRequest("account/reset-password", resetPasswordModel)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const ConfirmEmail = (ConfirmEmailModel) => {
  return new Promise((resolve, reject) => {
    postRequest("account/confirm-email", ConfirmEmailModel)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const LogOut = () => {
  return new Promise((resolve, reject) => {
    getRequest("account/logout")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const authServices = {
  SignUp,
  Login,
  ForgotPassword,
  ResetPassword,
  ConfirmEmail,
  SignInUsingGoogle,
  EmailExist,
  LogOut
};
export default authServices;
