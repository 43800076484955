import * as Yup from "yup";

const SIGNUP_VALIDATION = Yup.object().shape({
    firstName: Yup.string().required("First Name Required")
    .min(2, "First Name must be minimum 2 characters")
    .max(50, "First Name must be maximun 50 characters")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    lastName: Yup.string()
    .required("Last Name Required")
    .min(2, "Last Name must be minimum 2 characters")
    .max(50, "Last Name must be maximun 50 characters")
    .matches(/^[^\s]+(\s+[^\s]+)*/, "Characters must be alphanumaric"),
    password: Yup.string().required("Password Required")
    .min(8, "Password must be  8 characters")
    .max(16, "Password  must be maximun 16 characters")
    .matches(/[^A-Za-z 0-9]/g,'Password is too weak'),
  });

  const LOGIN_VALIDATION = Yup.object().shape({
    email: Yup.string()
      .email("Valid Email Required")
      .required("Email Required"),
    password: Yup.string().required("Password Required"),
  });

  const FORGOT_PASSWORD_VALIDATION = Yup.object().shape({
    email: Yup.string()
      .email("Valid Email Required")
      .required("Email Required"),
  });

  const CHANGE_PASSWORD_VALIDATION = Yup.object().shape({
    password: Yup.string().required("Password Required")
    .min(8, "Password must be  8 characters")
    .max(16, "Password  must be maximun 16 characters")
    .matches(/[^A-Za-z 0-9]/g,'Password is too weak'),
    confirmpassword: Yup.string()
      .required("Password Required")
      .oneOf([Yup.ref("password"), null], "Your password doesn’t match"),
  });

  const GET_STARTED_VALIDATION = Yup.object().shape({
    email: Yup.string()
      .email("Valid Email Required")
      .required("Email Required"),
  });


  export {
    SIGNUP_VALIDATION,
    LOGIN_VALIDATION,
    FORGOT_PASSWORD_VALIDATION,
    CHANGE_PASSWORD_VALIDATION,
    GET_STARTED_VALIDATION
  }