import React from "react";
import { Button } from "react-bootstrap";
import BackgroundVideo from "../../assets/images/IMSA-Video-22-6-22.mp4";
import { heroSection } from "../../constants/home-page-constants";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate()
  const handleTransaction = async () => {
    navigate('/claim')
  };

  return (
    <>
      <section className="hero-section">
        <video autoPlay loop muted className="hero-video">
          <source src={BackgroundVideo} type="video/mp4"></source>
        </video>

        <div className="hero-content">
          <h1 className="text-uppercase p-40 bg-black">
            {heroSection.LIVE_LIFE_IN_FASTLANE}
          </h1>
          <Button className="hero-btn mt-40" onClick={handleTransaction}>
            Buy Now
          </Button>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
