import MintingContent from "../../components/minting-page/MintingContent";

const Minting = () => {
  return (
    <>
      <MintingContent />
    </>
  );
};

export default Minting;
