import React from 'react'
import PurchaseSection from '../../components/purchase-page/PurchaseSection'


const Purchase = () => {
  return (
    <>
    <PurchaseSection />
    </>
  )
}

export default Purchase