import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
const EmailStatus = (props) => {
  const navigate = useNavigate()
  return (
    <>
      
        {props.message ? (
          <>
           <h4 className="text-uppercase mb-8">
           Your Account Has  been already Verified
         </h4>
         <p className="text-light-grey mb-40 ">
        Mi lorem scelerisque accumsan orci bibendum ultrices ultricies suspendisse.
        </p>
         <Button className="btn-primary text-uppercase w-100" onClick={() =>navigate('/login')}>Log into your account</Button>
         </>
        ) : (
          <>
          <h4 className="text-uppercase mb-8">
          Your Account Has been Verified
        </h4>
        <p className="text-light-grey mb-40 ">
        Mi lorem scelerisque accumsan orci bibendum ultrices ultricies suspendisse.
        </p>
        <Button className="btn-primary text-uppercase w-100" onClick={() =>navigate('/login')}>Log into your account</Button>
        </>
        )}
   
    </>
  );
};

export default EmailStatus;
