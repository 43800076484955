import React from "react";
import { Button } from "react-bootstrap";
import { TransactionSuccessIcon } from "../../../../constants/images-constants";


const TransactionSuccess = (props) => {
  const { setopenTransactionModal } = props;
  return (
    <>
      <h4 className="text-uppercase mb-8">Transaction COMPLETED</h4>

      <p className="mb-40">
        Amet diam at hendrerit quam nullam iaculis turpis nisl varius.
      </p>
      <div className="mb-40 text-center ">
        <img src={TransactionSuccessIcon} alt="success-icon" />
      </div>
      <Button
        className="btn-primary w-100"
        onClick={() =>
          setopenTransactionModal({ show: false, status: "success" })
        }
      >
        View my Collection
      </Button>
    </>
  );
};

export default TransactionSuccess;
