const FaqsContentConstants = {
  PERSONAL_PRIVACY:
    "We are always conscious and respectful of your personal privacy",
  PERSONAL_PRIVACY_GOVERNS: `This Privacy Policy governs the use of the International Motor
    Sports Association, LLC (“Company”) website located at IMSA.com
    (including its sub-domains) and associated applications (such as
    mobile and other device applications) and other associated
    offerings that are owned, offered or operated by or on behalf of
    Company (or its parent or affiliated entities) (“Site”). The
    Privacy Policy’s purpose is to make you aware of our privacy
    policy and practices and the way information is collected and
    used with respect to the Site. To make this notice easy to find,
    a link to this Privacy Policy is on the homepage of the Site.
    This Privacy Policy will answer your questions about the kind of
    information we collect and how we use it. If we change or update
    our Privacy Policy, we will post those changes here. By using
    the Site, you acknowledge you have read this Privacy Policy and
    agree to the terms hereof.`,
  INFO_GETTING: ` What information do you gather and how do you use it?`,
  INFO_COLLECTION: ` Company automatically collects certain non-personally
    identifiable information about your visits to the Site, such as
    IP address, service provider, browser type, operating system,
    pages accessed on the Site and the date and time of access to
    optimize your user experience and enhance your time spent with
    us online. Company also receives information telling us the site
    from which you originated. When you send Company an e-mail,
    Company will retain the content of the e-mail, your e-mail
    address, and our response in order to handle any follow-up
    questions you may have. When purchasing tickets or merchandise,
    joining as a member, registering for promotions at the Site, or
    in connection with gifts, ecards, e-vites, forward-to-a-friend
    messages or any other messaging service, Company may ask for
    your Name E-Mail Address DOB Phone Number We use the personally
    identifiable information you choose to provide to Company to
    process the transactions and services you request, to provide
    Company and Company affiliates promotional communications to
    you, to enable you to participate in sweepstakes and promotions,
    and to otherwise facilitate your use of the Site. Company may
    ask you to voluntarily provide us with information regarding
    your personal or professional interests, demographics,
    experiences with us, and contact preferences. This information
    may be combined with information about you, which Company
    gathered from other offline sources. Company and its affiliates
    use the information to design offers customized to your
    interests.`,
  PERSONAL_INFO: " Who do you share my personal information with?",
  PERSONAL_INFO_POLICY: ` Company’ general policy is that Company does not sell or share
    your name or other personally identifiable information to or
    with any non-affiliate third parties for marketing purposes
    unless you affirmatively agree to such sharing. However, Company
    may share your name and personally identifiable information with
    its affiliate companies for such affiliates to send you
    promotional communications such as emails, phone calls and
    newsletters. Company may share anonymous, aggregated information
    (information that does not individually identify a user) about
    all our users with third parties for any lawful purpose.`,
  SERVICE_PROVIDER: ` Service providers`,
  SERVICE_PROVIDER_POLICY: `  Company may also share information with companies that provide
    support services to us (such as a printer, mailing house or web
    host) or that help us market our products and services. These
    companies may need information about you in order to perform
    their functions. These companies are not authorized to use the
    information we share with them for any other purpose.`,
  PROTECTION: "Protection of the Site and others",
  PROTECTION_POLICY: `Company may disclose information you provide at the Site when we
    believe disclosure is appropriate to comply with the law; to
    enforce or apply our Terms of Use and other agreements; or to
    protect the rights, property or safety of the Site, our users or
    other individuals.`,
  SITE_COOKIES: `How does this Site use cookies and similar tracking
    technologies?`,
  SITE_COOKIES_POLICY: `This Site uses cookies, tags, pixels and similar tracking
    technologies to allow Company to recognize your preference
    information, remember your Member Login and facilitate effective
    site administration. We also use the services of reputable third
    parties to provide us with data collection, reporting, ad
    response measurement, and site analytics, as well as to assist
    with delivery of relevant marketing messages and advertisements.
    These third parties may view, edit or set their own cookies. We,
    our third party service providers, advertisers and/or partners
    may also place web beacons for these third parties. The use of
    these technologies by these third parties is subject to their
    own privacy policies and is not covered by this privacy policy.
    One such third party we use is Google Analytics. You may opt out
    of this feature using the Ads Preferences Manager:`,
  SITE_COOKIES_LINK: `https://www.google.com/settings/ads?hl=en&sig=ACi0TCgNEacab29ojFBLVmydWy `,
  SITE_COOKIES_POLICY_TWO: `At this time the Site does not recognize automated browser
    signals regarding tracking mechanisms, which may include “do not
    track” instructions. However, you may elect to refuse cookies by
    changing your privacy preferences regarding the use of cookies
    and similar technologies through your browser. You may set your
    browser to accept all cookies, block certain cookies, require
    your consent before a cookie is placed in your browser, or block
    all cookies. Blocking all cookies will affect your online
    experience, since certain Site functions or access may not be
    available if you choose to do so. Please refer to your browser
    “Help” instructions to learn more about cookies.`,
  CHILD_PRIVACY: `How does this Site comply with the Children's Online Privacy
    Protection Act?`,
  CHILD_PRIVACY_POLICY: `The Website is not intended for use by children under 13 years
    of age. Company does not knowingly collect personal information
    from anyone under 13 years of age, except for the limited
    circumstances described below. In the event a child under the
    age of 13 submits a question to us on the Site and identifies
    his or her age, we will use commercially reasonable efforts to
    limit our use of the child’s email address for the sole purpose
    of responding to the child’s question on a one-time-only basis.
    However, we will not use the child’s email address to re-contact
    the child and we will delete it from our records once we have
    responded. If we become aware that we have unknowingly collected
    personal information from a child under the age of 13, we will
    make commercially reasonable efforts to delete such information
    from our database.`,
  PROMO_COMM: `What choices do I have regarding promotional communications?`,
  PROMI_COMM_POLICY: `Company only sends promotional e-mail messages to you if you
    have voluntarily provided Company with your e-mail address.
    Further, by providing your email address you agree that Company
    may share such information with Company affiliates and that such
    Company affiliates may directly send you promotional materials.
    You may request to be removed from the mailing list by clicking
    on “CONTACT” in the footer of the Site and sending an email to
    Company requesting to be removed. You may unsubscribe from any
    e-mail subscription by following the unsubscribe procedures set
    forth in each e-mail, including those received from Company
    affiliates.`,
  PERSONAL_INFO_UPDATE:
    "How do I update personal information such as my address?",
  PERSONAL_INFO_UPDATE_POLICY: `Company asks that you keep your information as up-to-date as
    possible so you may get the maximum benefit from this Site. By
    clicking on “CONTACT” at the footer of the Site you can send an
    email to company with your updated information.`,
  SITE_SECURITY: "What about security at the Site?",
  SITE_SECURITY_POLICY: `We maintain physical, electronic and procedural safeguards that
    comply with applicable federal privacy and data security laws
    and regulations to guard your personal information.
    Unfortunately, no transmissions or data available through the
    Internet can be 100% secure and, accordingly, we cannot
    guarantee or warrant the security of any personal information or
    other information you provide to us. Company works to protect
    the security of your information during transmission. It is
    important for you to protect against unauthorized access to your
    import FaqsContentConstants from './../../constants/faqs-constants/index';
password and to your computer. Be sure to sign off when finished
    using a shared computer.`,
  LINKS: "What about links to and from other websites?",
  LINKS_POLICY: `This Site may offer links to other websites, or you may have
    arrived at this Site from websites that are operated by entities
    other than Company. These third party websites have their own
    policies regarding privacy. If you visit one of these websites,
    or if you arrived from one of these websites, you should review
    the privacy policy of the website to understand how your
    personal information is collected, used, secured and disclosed.
    Company is not responsible for the privacy practices of these
    third party websites and cannot guarantee the security of any of
    your personal information collected, used or shared there.`,
  BUSSINESS: "Business transfers and bankruptcy",
  BUSSINESS_POLICY: `If some or all of the business assets of Company or the Site are
    sold or transferred, your personal information, aggregated
    information, and any other information that we have collected in
    connection with the Site may be transferred as part of our
    business assets. In such case, we also may retain a copy of this
    information. Also, if any bankruptcy or reorganization
    proceeding is brought by or against us, all such information may
    be considered one of our assets and may be sold or transferred
    to third parties.`,
  COMMUNITY_FEATURE: "Community Feature",
  COMMUNITY_FEATURE_POLICY: `Any information, including Personal Information, that you
    contribute to a public area or feature of the Site, such as a
    user forum, blog, wiki or other open forum on the Site, will be
    made available to the general public. Therefore, we recommend
    that you do not submit any sensitive Personal Information to
    these public forums. Company is not responsible for the Personal
    Information or other information you choose to submit to these
    forums.`,
  INTERNATIONAL_USER: "International Users",
  INTERNATIONAL_USER_POLICY: `As this Site is operated from the United States and our computer
    systems are currently based in the United States, personal
    information submitted at the Site will be processed by Company
    in the United States where data protection and privacy
    regulations may differ from other parts of the world, such as
    the European Union. By providing personal information at this
    Site, you understand and consent to the collection, maintenance,
    processing and transfer of such information in and to the United
    States. By using this Site, you signify your assent to the
    Company Privacy Policy. If you do not agree to the Company
    Privacy Policy, please do not use this Site. We reserve the
    right, at our complete discretion, to change, modify, add, or
    remove portions of this policy at any time. View previous
    privacy policies by CLICKING HERE. Contact Information
    International Motor Sports Association, LLC Postal Address: One
    Daytona Blvd. Daytona Beach, FL 32114 Attention: Webmaster Email
    Address: `,
  INTERNATIONAL_USER_POLICY_LINK: "info@imsafastlane.com",
  EUROPEAN_USER: "Your European Privacy Rights.",
  EUROPEAN_USER_POLICY: `Please note that if you are in the European Union or the
    European Economic Area, you also have the right to:`,
  EUROPEAN_USER_POLICY_ONE: `obtain confirmation of the existence of your information in
    our database`,
  EUROPEAN_USER_POLICY_TWO: `verify the origin and accuracy of your information`,
  EUROPEAN_USER_POLICY_THREE: `request that we correct, update, amend, delete, or transform
    your information into anonymous form`,
  EUROPEAN_USER_POLICY_FOUR: `request that we correct, update, amend, delete, or transform
    your information into anonymous form`,
  EUROPEAN_USER_POLICY_FIVE: ` oppose the processing of your information on legitimate
    grounds`,
  EUROPEAN_USER_POLICY_SIX: ` oppose the processing of your information on legitimate
    grounds`,
  EUROPEAN_USER_POLICY_VERIFICATION: `Please know that in order for us to respond to your request we
    may require that you verify your identity. We are based in the
    United States and our products are offered in English. Requests
    submitted in another language will require translation`,
  CALIFORNIA_RESIDENT: " Rights for Our California Residents",
  CALIFORNIA_RESIDENT_POLICY: `This sections explains how we collect and share personal
    information about California residents (“California Customers”)
    and their privacy rights.`,
  CALIFORNIA_CONSUMER: `1. Notice of Information Collection for California Consumers`,
  CaLIFORNIA_PRIVACY_RIGHT: `II. Your California Privacy Rights`,
  WHAT_CaLIFORNIA_PRIVACY_RIGHT: `What Rights Do You Have?`,
  RIGHT_TO_KNOW: "Right to Know",
  RIGHT_TO_KNOW_DESC: ` - You have the right to ask us to
                tell you what personal information of yours we collect,
                use, share or sell.`,
  RIGHT_TO_ACCESS: "Right to Access",
  RIGHT_TO_ACCESS_DESC: ` -  – You have the right to request
    access to the personal information of yours we collect or
    maintain.`,
  RIGHT_TO_REQUEST: "Right to Request Deletion",
  RIGHT_TO_REQUEST_DESC: `- You have the right to
    ask us to delete the personal information we collect or
    maintain about you.`,
  DISCRIMINATION: "No Discrimination",
  DISCRIMINATION_DESC: `- You have the right not to be
    discriminated against for exercising the rights above.`,
  LIGHT_RIGHT: `You also have Shine the Light Rights`,
  LIGHT_RIGHT_DESC: `– You have the
    right to request and obtain from us, once a year and free of
    charge, a list of the third parties to whom we have disclosed
    certain types of personal information (if any) for their
    direct marketing purposes in the prior calendar year. At our
    option, we may respond to such requests by providing
    instructions about how our users can exercise their options to
    prevent our disclosure of personal information to third
    parties for their direct marketing purposes.`,
  SUBMIT_REQUEST: ` III. How Do You Submit These Requests to Us?`,
  EXERCISE_RIGHT: " California residents can exercise these rights:",
  REQUEST_TO_ACCESS: "Shine the Light Rights",
  REQUEST_TO_KNOW: "Requests to Access/Know",
  REQUEST_TO_DEL: "Request to Delete",
  CONTACT_US: "By contacting us by:",
  EMAIL: "Email",
  EMAIL_ADDR: ": info@imsa.com",
  POSTAL_ADDRESS: "By contacting us by:",
  POSTAL_ADDRESS_DETAIL: `One Daytona Blvd. Daytona Beach, FL
    32114 Attention: IMSA Webmaster`,
  PHONE: "Phone: 386.310.6500",
  VERIFY_DETAIL: `We will need to verify that you are who you say you are before
    we delete or share personal information with you. After you
    submit your request to our individual rights management tool,
    you will be sent a confirmation email and need to click on the
    link provided. If you make a request via email, we may need to
    request more information from you in order to verify your
    request. Effective Date: December 31, 2019`,
  TAG: "© 2020 International Motor Sports Association, LLC",
  TABLE_HEADING_TITLE_ONE: `WHAT INFORMATION DO WE COLLECT ABOUT YOU?`,
  TABLE_HEADING_TITLE_TWO: `WHERE DO WE COLLECT THIS INFORMATION FROM?`,
  TABLE_HEADING_TITLE_THREE: ` WHY DO WE COLLECT THIS INFORMATION?`,
  TABLE_HEADING_TITLE_FOUR: ` WHO DO WE SHARE THIS INFORMATION WITH?`,
  TABLE_CONTACT: `(e.g., name, email, postal
        address, zip code, telephone number, etc.)`,
  TABLE_COTACT_HEADING: "Contact Information",
  TABLE_FROM_USE: "From you",
  TABLE_FROM_USE_CONTENT: `when you register on our sites,
  subscribe to one of our newsletters, memberships,
  sign up for a service, promotion, purchase our
  products, or otherwise submit this information to
  us.`,
  TABLE_TO_CONTENT: ` contact you.`,
  TABLE_TO_PROVIDE: ` provide products or services `,
  TABLE_TO_PROVIDE_DESC: `to you, or handle your other requests. `,
  TABLE_SERVICE: `market our products and services `,
  TABLE__SERVICE_DESC: `to you.`,
  TABLE_ACCOUNT: `create and manage your account`,
  TABLE_ACCOUNT_DESC: `when you sign up, and
  to service your account. `,
  TABLE_PREVENT_DEFAULT: `prevent fraud and protect our customers and business, `,
  TABLE_PREVENT_DEFAULT_DESC: `including verifying your identity, and securing our track
  facilities. `,
  TABLE_PROVIDER: `Service Providers `,
  TABLE_PROVIDER_DESC: `(companies that use your personal
    information to provide services to us, including advertising,
    administering contests, website support, analytics, payment
    processing, and customer service). `,
  TABLE_IMSA_FAMILY: `IMSA family, including our affiliates. `,
  TABLE_REGISTER_INFO: `(e.g., name, username,
        password, account preferences, income level, etc.)`,
  TABLE_REGISTER_DESC: ` when you create an account or register with
        us.`,
  TABLE_CREATE_ACCOUNT: `when you sign up, and
        to service your account.`,
  TABLE_INTEREST_DESC: `(e.g.,
            interests (such as the athletes and brands you like) ,purchase
            behaviors, age, race, gender)`,
  TABLE_INTEREST: `Interests, Demographics and other Behavioral Data`,
  TABLE_FORM_CONSTANT: `you when you share that information with us on
            surveys, registration forms or other forms.`,
  TABLE_IDENTITY: `help us identify you and understand your interests`,
  TABLE_IDENTITY_DESC: `and preferences so that we can customize your experience with
            us.`,
  TABLE_ONLINE_IDENTIFIER: `(e.g., IP addresses, device IDs,
                operating systems, mobile network providers, settings, and other
                information we use to recognize your device)`,
  TABLE_TRACK: ` tracking technologies (such as pixels or cookies placed on
                    web pages or emails)`,
  TABLE_TRACK_DESC: `, which collect information about you when you visit us.`,
  TABLE_IMPROVE: `improve our products and services.`,
  TABLE_EFFECT: `help us measure the effectiveness of our platforms and the
  ads they display`,
  TABLE_EFFECT_DESC: ` , including the locations visitors frequent on our sites,
  whether you click on ads or buy the products/services
  advertised.`,
  TABLE_EXP: `customize your experience with`,
  TABLE_EXP_DESC: `so that you receive
  content that is relevant to you.`,
  TABLE_ACTIVITY: `Online Activity Information`,
  TABLE_ACTIVITY_DESC: `(e.g., browsing history, search
    history, and other information about your interactions with our
    platforms`,
  TABLE_TRACKING: `tracking technologies (such as pixels or cookies placed on
        web pages or emails)`,
  TABLE_TRACKING_DESC: `, which collect information about you when you visit our
        online platforms.`,
  TABLE_COSTOMISE: `To customize your experience on our platforms so that you receive relevant content.`,
  TABLE_PAY: "Payment Information",
  TABLE_PAY_DESC: `(e.g., billing address, shipping
    address, credit card or bank account number, card verification
    value, etc.)`,
  TABLE_PAY_FOR: `From you when your place an order for our products or services or
    make a reservation.`,
  TABLE_PAY_PROCESS: `process your orders, reservations,`,
  TABLE_PAY_REFUND: `and any refund
    requests.`,
  TABLE_PAY_VERIFY: `To verify your identity`,
  TABLE_PAY_VERIFY_DESC: `when you contact us about your
    purchases or reservations.`,
  TABLE_LOCATION: `Location Information `,
  TABLE_LOCATION_DESC: `(e.g., information about your location
        or device such as city, state or zip code)`,
  TABLE_LOCATION_SERVICE: `when using our services, such our interactive
        game sites.`,
  TABLE_LOCATION_PROVIDER: ` companies allowed to only use your
        personal information to provide services to us, including
        hosting our sites and mobile apps, securing our track
        facilities, analytics, and advertising).`,
  TABLE_LOCATION_CUSTOM: `customize your experience`,
  TABLE_LOCATION_CUSTOM_DESC: `so that you receive
        relevant information about our services and products (e.g.,
        upcoming events in your area, perks available near you, access
        to location-based social networks and communities, etc.)`,
  TABLE_LOCATION_PROVIDE: `protect our customers and business`,
  TABLE_LOCATION_PROVIDE_DESC: `, including our
        platforms and security at our track facilities.`,
  TABLE_LOCATION_SERVICE_DESC: `(companies allowed to only use your
            personal information to provide services to us, including
            advertising, website support, and analytics).`,
  TABLE_LOCATION_PARTNER: `Data Partners `,
  TABLE_LOCATION_PARTER_DESC:`(these are companies that we share
    personal information with to help us identify potential
    customers, and to understand who you are and what you may be
    interested in).`,
    TABLE_LOCATION_AFFLIATE:"IMSA affiliates.",
    TABLE_OTHER_COMPANY:`Other companies in the`,
    TABLE_ONLINE_DEVICE:`Online/Device Identifiers`,
    TABLE_CREATE:`To create and manage your account`,
    TABLE_ACCOUNT_REG:`Account/Registration Information`
};

export default FaqsContentConstants;
