import { useFormik } from "formik";

const FormikUtil = (INITIAL_FORM_STATE, FORM_VALIDATION, methodName) => {
  return useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_FORM_STATE,
    validationSchema: FORM_VALIDATION,

    onSubmit: (values) => {
      methodName(values);
    },
  });
};

export default FormikUtil;
