import ClaimNftContent from "../../components/claim-page/ClaimNftContent";

const ClaimNft = () => {
  return (
    <>
      <ClaimNftContent />
    </>
  );
};

export default ClaimNft;
