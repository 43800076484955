import * as fcl from "@onflow/fcl";
import { blockchainMessage } from "../../constants/messages-constants";

const DapperTransactions = async (script) => {
  let transactionStatus = {
    error: false,
    message: "",
  };

  const blockResponse = await fcl.send([fcl.getBlock()]);
  const block = await fcl.decode(blockResponse);
  try {
    const tx = await fcl.send([
      fcl.transaction(script),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(block.id),
      fcl.limit(9999),
      fcl.authorizations([
        fcl.currentUser().authorization, // add this
      ]),
    ]);

    const { transactionId } = tx;

    const transaction = await fcl.tx(transactionId).onceSealed();
    if (
      (transaction?.status === 4 || transaction?.status === 3) &&
      transaction?.errorMessage
    ) {
      transactionStatus.error = true;
      transactionStatus.message = transaction?.errorMessage;
      return transactionStatus;
    }
    if (
      (transaction?.status === 4 || transaction?.status === 3) &&
      transaction?.events[0]?.data?.amount
    ) {
      transactionStatus.error = false;
      transactionStatus.message = transactionId;
      return transactionStatus;
    }
  } catch (error) {
    transactionStatus.error = true;
    transactionStatus.message = error ?? blockchainMessage.TRANSACTION_FAILED;
    return transactionStatus;
  }
};

export default DapperTransactions;
