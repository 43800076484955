import React from "react";
import ProfileContent from "../../components/profile-page/ProfileContent";

const Profile = () => {
  return (
    <>
      <ProfileContent />
    </>
  );
};

export default Profile;
