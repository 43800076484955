import React from "react";
import { Button } from "react-bootstrap";
import { TransactionFailedIcon } from "../../../../constants/images-constants";


const TransactionFailed = (props) => {
  const { setopenTransactionModal } = props;
  return (
    <>
      <h4 className="text-uppercase mb-40">Transaction Failed</h4>
      <div className=" d-flex align-items-start mb-40 ">
        <img className="mr-12" src={TransactionFailedIcon} alt="failed-icon" />
        <p>
          Tellus ut nibh tempor nunc. Cursus tortor, senectus sagittis, nunc,
          non facilisis sed. Faucibus ipsum nunc, ut urna commodo aenean tortor
          aliquet. Contact Support.
        </p>
      </div>
      <Button
        className="btn-primary w-100"
        onClick={() =>
          setopenTransactionModal({ show: false, status: "failed" })
        }
      >
        Try Again
      </Button>
    </>
  );
};

export default TransactionFailed;
