import React, { useState } from "react";
import ModalPopup from "../../common/ModalPopup/ModalPopup";
import SendUsdModal from "./SendUsdModal";
import CirclePaymentService from "./../../../services/circlePaymentService/circlePaymentService";
import { setLoader } from "./../../../redux/actions/loaderActions/loadActions";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";

import CustomCheckbox from "../../common/CustomCheckbox";
import CircleOrderDetail from "./CircleOrderDetail";
import CirclePaymentOptions from "./CirclePaymentOptions";
import { showAlert } from "./../../../redux/actions/alertActions/alertActions";
import { useSelector } from "react-redux";
import { useRef } from "react";
import QRCode from 'qrcode'
import { AlgoIcon, EthIcon, SolIcon, StellerIcon, TronIcon } from "../../../constants/images-constants";
import { requestErrorMessage } from "../../../constants/messages-constants";
const CircleSelectUsdChain = ({
  openSelectChainModal,
  setopenSelectChainModal,
  setopenCircleCard,
  selectPaymentOption,
  setselectPaymentOption,
  setopenCheckUsdStatusModal,
  setWalletAddress,
  walletAddress
  
}) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [qrImage, setqrImage] = useState("");
  const scrollTo = () => {
    window.scrollTo({ behavior: 'smooth', top: myRef.current.scrollIntoView()})
   
  }
  const {  userid } = useSelector((state) => state.loginReducer);
  
  
  const [openSendUsdModal, setOpenSendUsdModal] = useState(false);

  const [selectedChain, setselectedChain] = useState("");

  const handleSelectedChain = (chain) => {
    dispatch(setLoader(true));
    CirclePaymentService.createWalletAddress(chain ,userid)
      .then(async(response) => {
        dispatch(setLoader(false));
        if (response?.data?.errorCode === 0) {
          if (response?.data?.payload !== null) {
            setWalletAddress(response.data.payload.data.address);
            setqrImage(await QRCode.toDataURL(response.data.payload.data.address))
            setOpenSendUsdModal(true);
           // setopenSelectChainModal(false);
          }
          else {
            dispatch(showAlert(response.data.message, "danger", true));
            setopenSelectChainModal(false);
            setselectPaymentOption("pay-with-card")
          }
        } else {
          dispatch(showAlert(response.data.message, "danger", true));
            setopenSelectChainModal(false);
            setselectPaymentOption("pay-with-card")
        }
      })
      .catch((error) => {
        dispatch(setLoader(false));
        setselectPaymentOption("pay-with-card")
        dispatch(showAlert(requestErrorMessage.SOMETHING_WENT_WRONG, "danger", true));
        setopenSelectChainModal(false);
      });
  };

  return (
    <>
      <ModalPopup
        show={openSelectChainModal}
        onHide={() => setopenSelectChainModal(false)}
        modelheading={""}
        showheader={"true"}
        dialogClassName="modal-dialog-centered "
       // centerclassname=""
        scrollable={true}
        modelbody={[
          <div className="">
            <div className="mb-40">
              <h4 className="text-uppercase">Get your Platinum access</h4>
              <p className="text-light-grey">
                Amet diam at hendrerit quam nullam iaculis turpis nisl varius.
              </p>
            </div>
            <CirclePaymentOptions
              setopenCircleCard={setopenCircleCard}
              setopenSelectChainModal={setopenSelectChainModal}
              selectPaymentOption={selectPaymentOption}
              setselectPaymentOption={setselectPaymentOption}
            />
            <div className="my-4 mx-3">
            <CustomCheckbox
            icon={EthIcon}
            title={"Ethereum USDC"}
            subtitle={"Approximate transaction time 5 mins"}
            selectedChain={selectedChain}
            selected={"ETH"}
            handleChain={() => {
              scrollTo();
              setselectedChain("ETH");
              
            }}
          />
          <CustomCheckbox
            icon={EthIcon}
            title={"Flow USDC"}
            subtitle={"Approximate transaction time 5 mins"}
            selectedChain={selectedChain}
            selected={"FLOW"}
            handleChain={() => {
              setselectedChain("FLOW");
              scrollTo()
            }}
          />
          <CustomCheckbox
            icon={SolIcon}
            title={"Solana USDC"}
            subtitle={"Approximate transaction time 10 mins"}
            selectedChain={selectedChain}
            selected={"SOL"}
            handleChain={() => {
              setselectedChain("SOL");
              scrollTo()
            }}
          />
           <CustomCheckbox
            icon={SolIcon}
            title={"Matic USDC"}
            subtitle={"Approximate transaction time 10 mins"}
            selectedChain={selectedChain}
            selected={"MATIC"}
            handleChain={() => {
              setselectedChain("MATIC");
              scrollTo()
            }}
          />
          <CustomCheckbox
            icon={AlgoIcon}
            title={"Algorand USDC"}
            subtitle={"Approximate transaction time 30 secs"}
            selectedChain={selectedChain}
            selected={"ALGO"}
            handleChain={() => {
              setselectedChain("ALGO");
              scrollTo()
            }}
          />
          <CustomCheckbox
            icon={AlgoIcon}
            title={"Avalanche USDC"}
            subtitle={"Approximate transaction time 30 secs"}
            selectedChain={selectedChain}
            selected={"AVAX"}
            handleChain={() => {
              setselectedChain("AVAX");
              scrollTo()
            }}
          />
          <CustomCheckbox
            icon={StellerIcon}
            title={"Stellar USDC"}
            subtitle={"Approximate transaction time 1 min"}
            selectedChain={selectedChain}
            selected={"XLM"}
            handleChain={() => {
              setselectedChain("XLM");
              scrollTo()
            }}
          />
          <CustomCheckbox
            icon={StellerIcon}
            title={"Hedera USDC"}
            subtitle={"Approximate transaction time 1 min"}
            selectedChain={selectedChain}
            selected={"HBAR"}
            handleChain={() => {
              setselectedChain("HBAR");
              scrollTo()
            }}
          />
          <CustomCheckbox
            icon={TronIcon}
            title={"TRON USDC"}
            subtitle={"Approximate transaction time 6 mins"}
            selectedChain={selectedChain}
            selected={"TRX"}
            handleChain={() => {
              setselectedChain("TRX");
              scrollTo()
            }}
          />
            </div>
            <div className="mt-24">
              <CircleOrderDetail />
            </div>
            <div className="d-flex "  ref={myRef}>
              <Button
                type="button"
                className="btn-secondary mr-12 w-50 text-uppercase"
                onClick={() => {setopenSelectChainModal(false)
                  setselectPaymentOption("pay-with-card")}}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn-primary w-50 text-uppercase"
                disabled={selectedChain === ""}
                onClick={() => {
                  handleSelectedChain(selectedChain);
                }}
              >
                Continue
              </Button>
            </div>
          </div>,
        ]}
      />
      {openSendUsdModal && (
        <SendUsdModal
           qrImage={qrImage}
           selectedChain={selectedChain}
          walletAddress={walletAddress}
          openSendUsdModal={openSendUsdModal}
          setOpenSendUsdModal={setOpenSendUsdModal}
          setopenCircleCard={setopenCircleCard}
          selectPaymentOption={selectPaymentOption}
          setselectPaymentOption={setselectPaymentOption}
          setopenSelectChainModal={setopenSelectChainModal}
          setopenCheckUsdStatusModal={setopenCheckUsdStatusModal}
          
        />
      )}
    </>
  );
};

export default CircleSelectUsdChain;
